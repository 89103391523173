import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import SecondaryButton from '../../Product/components/atoms/SecondaryButton'
import * as Yup from 'yup'
import Popup from '../../Product/components/organisms/Popup'
import { mapData } from '../../Product/common/components'
import PrimaryButton from '../../Product/components/atoms/PrimaryButton'
import { useContext } from 'react'
import pluginContext from '../../Product/context/plugin/pluginContext'
import alertContext from '../../Product/context/alert/alertContext'
const RequestRefund = ({
    lot_id,
    invoice_id,
    trigger,
    setTrigger,
    seller_refund_accept,
    isrefund_request,
    status,
    refund_request_notes,
}) => {
    const { requestRefund, responseStatus, clearResponse } = useContext(pluginContext)
    const { setAlert } = useContext(alertContext)
    const [openDispute, setOpenDispute] = useState(false)
    const [loading, setLoading] = useState(false)
    const validationSchema = Yup.object({
        reason: Yup.string().required('Reason is required!'),
    })
    const formik = useFormik({
        initialValues: {
            site_id: global?.storeDetails?.site_id,
            lot_id,
            invoice_id,
            reason: '',
        },
        validationSchema,
        onSubmit: (values) => {
            requestRefund(values)
            setLoading(true)
        },
    })

    useEffect(() => {
        if (refund_request_notes) {
            formik.setFieldValue('reason', refund_request_notes)
        } else {
            formik.setFieldValue('reason', '')
        }
    }, [refund_request_notes])

    const fields = [
        {
            label: 'Reason',
            placeholder: 'Enter Your Reason Here...',
            type: 'textarea',
            class: 'col-12 ',
            name: 'reason',
            disabled: refund_request_notes ? true : false,
            formik,
        },
    ]
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'requestRefund') {
                setLoading(false)
                if (responseStatus.status === 'success') {
                    setAlert(responseStatus.message.message, responseStatus.status)
                    formik.resetForm()
                    setOpenDispute(false)
                    setTrigger(!trigger)
                } else {
                    setAlert(responseStatus.message, responseStatus.status)
                }
            }
        }
        clearResponse()
    }, [responseStatus])
    return (
        <div>
            {global?.pluginConfiguration?.flutterwave_payment_gateway?.enable == 1 ? (
                <>
                    {parseInt(seller_refund_accept) == 0 &&
                    parseInt(isrefund_request) == 1 &&
                    status == 'status' ? (
                        <p>Please wait for admin approval.</p>
                    ) : parseInt(seller_refund_accept) == 1 && status == 'status' ? (
                        <p style={{ color: 'green' }}>
                            Your requested accepted by admin.Please send product to seller.
                        </p>
                    ) : parseInt(seller_refund_accept) == 2 && status == 'status' ? (
                        <p style={{ color: 'red' }}>Your requested rejected by admin.</p>
                    ) : parseInt(seller_refund_accept) == 3 && status == 'status' ? (
                        <p style={{ color: 'green' }}>Seller Recived Your Product.</p>
                    ) : parseInt(seller_refund_accept) == 4 && status == 'status' ? (
                        <p style={{ color: 'green' }}>Admin Refunded Your Amount.</p>
                    ) : parseInt(seller_refund_accept) == 5 && status == 'status' ? (
                        <p style={{ color: 'red' }}>Seller Requested Product Issues To Admin.</p>
                    ) : parseInt(seller_refund_accept) == 6 && status == 'status' ? (
                        <p style={{ color: 'red' }}>
                            Admin Release Partial Amount To You And Seller.
                        </p>
                    ) : status != 'status' ? (
                        <SecondaryButton
                            label={refund_request_notes ? 'View Return' : 'Return'}
                            onClick={() => setOpenDispute(true)}
                        />
                    ) : (
                        <p>{'-'}</p>
                    )}
                </>
            ) : (
                <SecondaryButton label="Request" onClick={() => setOpenDispute(true)} />
            )}

            <Popup
                open={openDispute}
                handleClose={(e) => setOpenDispute(false)}
                size="md"
                className="save-search-modal"
                modaltitle="Request Refund"
            >
                <div className="row justify-content-center">
                    {mapData(fields)}
                    {!refund_request_notes ? (
                        <div className="col-4">
                            <PrimaryButton
                                label={loading ? 'Loading...' : 'Submit'}
                                className="mbv-btn"
                                onClick={formik.handleSubmit}
                                disabled={loading}
                            />
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </Popup>
        </div>
    )
}

export default RequestRefund
