import React from 'react'
import Layout from '../Layout'

export default function FactsToKnow(props) {
    return (
        <Layout props={props}>
            <div className="px-5 py-4 staticPgWrapper">
                {/* <h2>About Us</h2> */}
                <span
                    dangerouslySetInnerHTML={{
                        __html: global.storeDetails.fact_to_know,
                    }}
                ></span>
            </div>
        </Layout>
    )
}
