import React, { useReducer, useState } from 'react'
import ProductContext from './productContext'
import ProductReducer from './productReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'
import {
    ADD_PRODUCT,
    GET_EDIT_PRODUCT,
    GET_ALL_USERS,
    GET_EDIT_AUCTION,
    GET_INVOICE,
    GET_INVOICE_NEW,
    GET_NEW_AUCTION,
    GET_ALL_SELLER_AUCTIONS,
    GET_ALL_SELLER,
    GET_ALL_SEARCH,
    GET_ALL_SIMILAR,
    GET_ACTIVE_BID_PRODUCT,
    GET_WON_BID_PRODUCT,
    GET_LOST_BID_PRODUCT,
    GET_ALL_SEARCH_AUCTIONS,
    GET_ALL_LOT_AUCTIONS,
    GET_SHIPPING_RATES,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    UPDATE_PROJ_DYN,
    GET_LA_SELLERS,
    GET_ALL_PRODUCT_VIEWED,
    GET_OVER_ALL_CATEGORIES,
    GET_SUB_CATEGORIES,
    GET_SELLER_DASHBOARD,
    UPCOMING_LIST,
    GET_ALL_UNPAID_USERS,
    GET_POS_BUYNOW_PRODUCTS,
    GET_ALL_BRAND_DETAILS,
    GET_ALL_DYNAMIC_FIELDS,
} from './productTypes'

const ProductState = (props) => {
    const initialState = {
        edit_product: {},
        all_active_users: [],
        edit_auction: {
            auction_details: [],
        },
        invoice_details: {},
        group_invoice_details: {},
        auction_lots: [],
        la_sellers: [],
        shipping_rates: {},
        search_allsimilarproducts: {
            results: [],
            locate: [],
            categoriesCounts: [],
            upcomingAuction: [],
            set_disp: 0,
            total_pagecnt: 0,
            total_only: 0,
        },
        search_allauctionproducts: {
            results: [],
            total_only: 0,
        },
        search_allproducts: {
            results: [],
            locate: [],
            state: [],
            city: [],
            categoriesCounts: [],
            maincategory: [],
            subcategoriesCounts: [],
            upcomingAuction: [],
            set_disp: 0,
            total_pagecnt: 0,
            total_only: 0,
            loading: true,
            game_them: '',
        },
        search_allauctions: {
            results: [],
            set_disp: 0,
            total_pagecnt: 0,
            total_only: 0,
        },
        // buyer_allproducts: [],
        buyer_activeproducts: [],
        buyer_wonproducts: [],
        buyer_lostproducts: [],
        seller_allproducts: {
            result: [],
            length: 0,
        },
        seller_allauctions: {
            result: [],
            length: 0,
        },
        responseStatus: null,
        allProductsViewed: {},
        categories: [],
        sub_categories: [],
        seller_dashboard_data: {},
        upcoming_list: [],
        all_unpaid_users: [],
        pos_buynow_products: [],
        brandsproducts: [],
        dynamicproducts: [],
    }

    const [state, dispatch] = useReducer(ProductReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)
    const [productLocalState, setState] = useState({
        globalLocations: ['California'],
        globalLocation: localStorage.getItem('globalLocation')
            ? localStorage.getItem('globalLocation')
            : 'California',
        globalLocationFilter: 0,
        disableBuynowLocations: [],
    })
    const [currency, setCurrency] = useState(
        localStorage.getItem('currency') ? localStorage.getItem('currency') : 'USD',
    )
    // Post Product

    const postProduct = async (formData) => {
        const from = 'postProduct'
        formData.set('auction_io', 1)
        try {
            const [res] = await Promise.all([
                apiCall('post', 'sellerpostprojectlive', formData, 'formdata'),
            ])
            if (res.data.status === 'true') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message:
                            formData.get('market_status') === 'draft'
                                ? 'Product saved in draft successfully'
                                : 'Product posted successfully',
                        responseData: [res.data.data.insertId],
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    // Post Product
    const editProduct = async (formData) => {
        const from = 'editProduct'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'sellerupdateproject', formData, 'formdata'),
            ])
            if (res.data.status === 'true') {
                resp.commonResponse(
                    { status: 'success', message: 'Product updated successfully' },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.failedreason }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    // Get edit Product
    const getEditProduct = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'getProductDetails', formData)])
            if (res.data.status === 'yes') {
                dispatch({
                    type: GET_EDIT_PRODUCT,
                    payload: res.data.data,
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.failedreason,
                })
            }
        } catch (err) {
            // dispatch({
            //   type: RESPONSE_STATUS,
            //   payload: 'Something went wrong!',
            // });
        }
    }
    const clearEditProduct = () => {
        dispatch({
            type: GET_EDIT_PRODUCT,
            payload: {},
        })
    }
    // Get Over All Catehories
    const getOverAllCategories = async (formData = {}, enable = '0') => {
        try {
            if (enable !== '1') {
                formData.auction_io = 1
            }
            const [res] = await Promise.all([apiCall('get', 'getoverallcatgeory', formData)])
            if (res.data.success) {
                dispatch({
                    type: GET_OVER_ALL_CATEGORIES,
                    payload: res.data.category_val,
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.message,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    // Get Sub Catehories
    const getSubCategories = async (formData = {}) => {
        try {
            const [res] = await Promise.all([apiCall('get', 'getoverallcatgeory', formData)])
            if (res.data.success) {
                dispatch({
                    type: GET_SUB_CATEGORIES,
                    payload: res.data.category_val,
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.message,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    // Get Over All Catehories
    const get_seller_dashboard = async (formData = {}) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'get_seller_dashboard')])
            if (res.data.success) {
                dispatch({
                    type: GET_SELLER_DASHBOARD,
                    payload: res.data.data,
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.message,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    // Get edit Product
    const getActiveUsers = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'getallUsers', formData)])
            if (res.data.success === 'yes') {
                dispatch({
                    type: GET_ALL_USERS,
                    payload: res.data.users,
                })
            } else {
                // dispatch({
                //   type: RESPONSE_STATUS,
                //   payload: res.data.failedreason,
                // });
            }
        } catch (err) {}
    }

    // Post Product
    const postAuction = async (formData, not_need_msg = 0) => {
        const from = 'postAuction'
        formData.append('auction_io', 1)
        formData.append('isconsecutive', 1)
        try {
            const [res] = await Promise.all([apiCall('post', 'createauctionlot', formData)])
            if (res.data.success) {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: 'Auction posted successfully',
                        responseData: res.data.data.auctionid,
                    },
                    from,
                    not_need_msg,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.data.error }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const changeMarketStatus = async (formData) => {
        const from = 'changeMarketStatus'
        try {
            const [res] = await Promise.all([apiCall('post', 'change_status', formData)])
            if (res.data.success) {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: `Status Changed to ${
                            formData.status == 'open' ? 'live' : formData.status
                        } successfully!`,
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const getInvoiceNew = async (formData) => {
        try {
            if (global.pluginConfiguration?.salestaxcity?.enable) {
                formData['city_sales_tax_enable'] = 1
            } else {
                formData['city_sales_tax_enable'] = 0
            }
            const [res] = await Promise.all([apiCall('post', 'view-invoice', formData)])
            if (res.data.success === 'yes') {
                const data = {
                    product_details:
                        formData.isAuction === 0
                            ? res.data.data
                            : res.data.results.arr_temp.SlibuyPayments,
                    buyer_details:
                        formData.isAuction === 0
                            ? res.data.userProfile
                            : res.data.results.arr_temp.userShippdetail,
                    seller_details:
                        formData.isAuction === 0
                            ? res.data.selleraddress
                            : res.data.results.arr_temp.selleraddress,
                    auction_details:
                        formData.isAuction === 0
                            ? res.data.auction_details
                            : res.data.results.arr_temp.auction_details,
                    pay_records:
                        formData.isAuction === 0
                            ? res.data.adminRecords
                            : res.data.results.arr_temp.adminpay,
                }
                dispatch({
                    type: GET_INVOICE_NEW,
                    payload: data,
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.reason,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getCheckoutAuction = async (formData) => {
        try {
            if (global.pluginConfiguration?.salestaxcity?.enable) {
                formData['city_sales_tax_enable'] = 1
            } else {
                formData['city_sales_tax_enable'] = 0
            }
            const [res] = await Promise.all([apiCall('post', 'getCheckoutAuction', formData)])
            if (res.data.success) {
                dispatch({
                    type: GET_INVOICE,
                    payload: res.data.details,
                })
            } else {
                dispatch({
                    type: GET_INVOICE,
                    payload: res?.data ? res.data : {},
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse_invoice_details = () =>
        dispatch({
            type: GET_INVOICE,
            payload: {},
        })

    const clearResponse_search_details = () =>
        dispatch({
            type: GET_ALL_SEARCH,
            payload: {
                results: [],
                locate: [],
                state: [],
                city: [],
                categoriesCounts: [],
                subcategoriesCounts: [],
                upcomingAuction: [],
                set_disp: 0,
                total_pagecnt: 0,
                total_only: 0,
            },
        })

    const getInvoice = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'getinvoicedetails', formData)])
            if (res.data.success) {
                dispatch({
                    type: GET_INVOICE,
                    payload: res.data.data.record,
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.data,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    // Post Product
    const getShippingRates = async (formData) => {
        formData.auction_io = 1
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getshippingrates', formData, '', 'auctionpay'),
            ])
            if (res.data.success) {
                dispatch({
                    type: GET_SHIPPING_RATES,
                    payload: res.data.data,
                })
            } else {
                resp.commonResponse(
                    { status: 'error', message: 'Service not available for this postal code!' },
                    'getshippingrates',
                )
            }
        } catch (err) {
            //console.log('error', err)
        }
    }

    // Post Product
    const postShipping = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'shippingapi', formData, '', 'auctionpay'),
            ])
            if (res.data.success) {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: 'successfully_shipmentapi',
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.data,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    // Post Product
    const postLocalPickup = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'localpickupapi', formData, '', 'auctionpay'),
            ])
            if (res.data.success) {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: 'successfully_localpickupapi',
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.data,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    // Get edit Product
    const getEditAuction = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'getauctionlotdetails', formData)])
            if (res.data.success) {
                dispatch({
                    type: GET_EDIT_AUCTION,
                    payload: {
                        ...res.data.data,
                        from: fromVariable,
                    },
                })
                dispatch({
                    type: GET_NEW_AUCTION,
                    payload: res.data.data.lot_details,
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.data,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    // Get edit Product
    const getNewAuction = async (formData) => {
        try {
            formData['isBiddedProducts'] = 'false'
            formData['status'] = 'draft'
            const [res] = await Promise.all([apiCall('post', 'getlotdetails', formData)])
            if (res.data.success) {
                dispatch({
                    type: GET_NEW_AUCTION,
                    payload: res.data.data,
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.data,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    // Post Product
    const getAllSellerProducts = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'getSellerProducts', formData)])
            if (res.data.status === 'yes') {
                dispatch({
                    type: GET_ALL_SELLER,
                    payload: res.data.data,
                })
            } else {
                dispatch({
                    type: GET_ALL_SELLER,
                    payload: {
                        result: [],
                        length: 0,
                    },
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllUnpaidUsers = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'get-all-unpaid-users', formData)])
            if (res.data.success) {
                dispatch({
                    type: GET_ALL_UNPAID_USERS,
                    payload: res.data.results,
                })
            } else {
                dispatch({
                    type: GET_ALL_UNPAID_USERS,
                    payload: [],
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getPosBuynowProducts = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'getPosBuynowProducts', formData)])
            if (res.data.status === 'yes') {
                dispatch({
                    type: GET_POS_BUYNOW_PRODUCTS,
                    payload: res.data.result,
                })
            } else {
                dispatch({
                    type: GET_POS_BUYNOW_PRODUCTS,
                    payload: [],
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    // Post Product
    const getAllSellerAuctions = async (formData) => {
        try {
            formData.auction_only = formData.auction ? 0 : 1
            const [res] = await Promise.all([apiCall('post', 'getSellerAuctions', formData)])
            if (res.data.status === 'yes') {
                //console.log('before dispath 1111')
                dispatch({
                    type: GET_ALL_SELLER_AUCTIONS,
                    payload: res.data.data,
                })
            } else {
                dispatch({
                    type: GET_ALL_SELLER_AUCTIONS,
                    payload: {
                        result: [],
                        length: 0,
                    },
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getLotList = async (formData, fromVariable, cancelToken) => {
        try {
            formData.auction_io = 1
            const [res] = await Promise.all([
                apiCall('post', 'lotSearch', formData, '', '', cancelToken),
            ])
            if (res.data.success) {
                if (res.data.result) {
                    dispatch({
                        type: GET_ALL_LOT_AUCTIONS,
                        payload:
                            fromVariable === 'auctionViewLive'
                                ? {
                                      results: res.data.response.results,
                                      total_results: res.data.response.total_results,
                                      isRegistered: res.data.response.isRegistered,
                                      isRegistered_list: res.data.response.isRegistered_list,
                                      current_projectid: res.data.response.current_projectid,
                                      auctionList: res.data.response.auctionList,
                                  }
                                : {
                                      results: res.data.result.listOfProjects.length
                                          ? res.data.result.listOfProjects
                                          : [],
                                      total_only: res.data.result.totalCount,
                                      from: fromVariable,
                                  },
                    })
                } else {
                    dispatch({
                        type: GET_ALL_LOT_AUCTIONS,
                        payload:
                            fromVariable === 'auctionViewLive'
                                ? {
                                      results: [],
                                      total_results: 0,
                                      isRegistered: 0,
                                      isRegistered_list: [],
                                      current_projectid: 0,
                                      auctionList: {},
                                  }
                                : {
                                      results: [],
                                      total_only: 0,
                                      from: fromVariable,
                                  },
                    })
                }
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.failedreason,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllSearchAuctions = async (formData, fromVariable) => {
        try {
            formData.auction_only = formData.auction ? 0 : 1
            const [res] = await Promise.all([apiCall('post', 'auctionsearch', formData)])
            if (res.data.success === 'yes') {
                if (res.data) {
                    dispatch({
                        type: GET_ALL_SEARCH_AUCTIONS,
                        payload: {
                            results: res.data.results.length ? res.data.results : [],
                            set_disp: res.data.set_disp,
                            total_pagecnt: res.data.total_pagecnt,
                            total_only: res.data.total_only,
                            from: fromVariable,
                        },
                    })
                } else {
                    dispatch({
                        type: GET_ALL_SEARCH_AUCTIONS,
                        payload: {
                            results: [],
                            set_disp: 0,
                            total_pagecnt: 0,
                            total_only: 0,
                            from: fromVariable,
                        },
                    })
                }
                return res.data
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.failedreason,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const getAllSimilarProducts = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'mobilesearch', formData)])
            if (res.data.success === 'yes') {
                if (res.data) {
                    dispatch({
                        type: GET_ALL_SIMILAR,
                        payload: {
                            results: res.data.results.length ? res.data.results : [],
                            set_disp: res.data.set_disp,
                            total_pagecnt: res.data.total_pagecnt,
                            total_only: res.data.total_only,
                            locate: res.data.locate.length ? res.data.locate : [],
                            categoriesCounts: res.data.categoriesCounts.length
                                ? res.data.categoriesCounts
                                : [],
                            upcomingAuction: res.data.upcomingAuction.length
                                ? res.data.upcomingAuction
                                : [],
                        },
                    })
                } else {
                    dispatch({
                        type: GET_ALL_SIMILAR,
                        payload: {
                            results: [],
                            locate: [],
                            categoriesCounts: [],
                            upcomingAuction: [],
                            set_disp: 0,
                            total_pagecnt: 0,
                            total_only: 0,
                        },
                    })
                }
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.failedreason,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const getAllSearchProducts = async (formData, fromVariable, cancelToken) => {
        try {
            formData.getbidding = true
            const [res] = await Promise.all([
                apiCall('post', 'mobilesearch', formData, '', '', cancelToken),
            ])
            if (res.data.success === 'yes') {
                if (res.data) {
                    dispatch({
                        type: GET_ALL_SEARCH,
                        payload: {
                            results: res.data.results.length ? res.data.results : [],
                            set_disp: res.data.set_disp,
                            total_pagecnt: res.data.total_pagecnt,
                            total_only: res.data.total_only,
                            locate: res.data.locate.length ? res.data.locate : [],
                            state: res.data.statehtm.length ? res.data.statehtm : [],
                            city: res.data.cityhtm.length ? res.data.cityhtm : [],
                            categoriesCounts: res.data.categoriesCounts.length
                                ? res.data.categoriesCounts
                                : [],
                            maincategory: res.data.maincategory.length ? res.data.maincategory : [],
                            subcategoriesCounts: res.data.subcategoriesCounts.length
                                ? res.data.subcategoriesCounts
                                : [],
                            upcomingAuction: res.data.upcomingAuction.length
                                ? res.data.upcomingAuction
                                : [],
                            from: fromVariable,
                            loading: false,
                            game_them: res.data.game_them.length > 0 ? res.data.game_them : [],
                        },
                    })
                } else {
                    dispatch({
                        type: GET_ALL_SEARCH,
                        payload: {
                            results: [],
                            locate: [],
                            state: [],
                            categoriesCounts: [],
                            maincategory: [],
                            subcategoriesCounts: [],
                            upcomingAuction: [],
                            set_disp: 0,
                            total_pagecnt: 0,
                            total_only: 0,
                            from: fromVariable,
                            loading: false,
                        },
                    })
                }
                return res.data
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.failedreason,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getActiveBidProducts = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'active', formData)])
            if (res.data.status === 'yes') {
                dispatch({
                    type: GET_ACTIVE_BID_PRODUCT,
                    payload: res.data.data,
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.failedreason,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getWonBidProducts = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'my-won', formData)])
            if (res.data.status === 'yes') {
                dispatch({
                    type: GET_WON_BID_PRODUCT,
                    payload: res.data.data,
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.failedreason,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getLostBidProducts = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'mybids_search/lost', formData)])
            if (res.data.status === 'yes') {
                dispatch({
                    type: GET_LOST_BID_PRODUCT,
                    payload: res.data.data,
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.failedreason,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const updateProjectDynamic = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'updateProjectDetails', formData)])
            if (res.data.status === 'yes') {
                dispatch({
                    type: UPDATE_PROJ_DYN,
                    payload: 'proj_updated_successfull',
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.message,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const updateBuyNowDynamic = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'updateBuyNowDetails', formData)])
            if (res.data.status === 'yes') {
                dispatch({
                    type: UPDATE_PROJ_DYN,
                    payload: 'buynow_updated_successfull',
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.message,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const addWatchlist = async (formData) => {
        const from = 'addWatchlist'
        try {
            const [res] = await Promise.all([apiCall('post', 'add_watchlist', formData)])
            if (res.data.status === 'yes') {
                resp.commonResponse({ status: 'success', message: 'Favorite Added' }, from)
                return res.data
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const removeWatchlist = async (formData) => {
        const from = 'removeWatchlist'
        try {
            const [res] = await Promise.all([apiCall('post', 'remove_watchlist', formData)])
            if (res.data.status === 'yes') {
                resp.commonResponse({ status: 'success', message: 'Favorite Removed' }, from)
                return res.data
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const report = async (formData) => {
        const from = 'report'
        try {
            const [res] = await Promise.all([apiCall('post', 'report', formData, 'blob')])
            if (res.data.success) {
                resp.commonResponse({ status: 'success', message: 'Generated successfully' }, from)
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const buyNowProduct = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'mobileConfirmBuynow', formData)])
            if (res.data.status === 'yes') {
                dispatch({
                    type: UPDATE_PROJ_DYN,
                    payload: 'buynow_paid_successfull',
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: res.data.message,
                })
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const getla_sellers = async () => {
        const [res] = await Promise.all([apiCall('get', 'getlasellers')])
        dispatch({
            type: GET_LA_SELLERS,
            payload: res.data,
        })
    }
    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    const productViewed = async (project_id, user_id) => {
        let data = { project_id, user_id }
        await apiCall('post', 'insertProductViewed', data)
    }
    const getProduct_viewed_all = async (data) => {
        const [res] = await Promise.all([apiCall('post', 'get_seller_products_viewed', data)])
        dispatch({
            type: GET_ALL_PRODUCT_VIEWED,
            payload: res.data,
        })
    }
    const relist = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'relist', formData, '', 'api')])
            const from = 'relist'
            if (res.data.status === 'success') {
                res.data.message = 'Relisted successfully!'
                resp.commonResponse(res.data, from)
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const repost = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'projects-repost', formData)])
            const from = 'repost'
            if (res.data.success) {
                res.data.status = 'success'
                res.data.message = 'Repost successfully!'
                resp.commonResponse(res.data, from)
            } else {
                res.data.status = 'error'
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const bulkUploadInventory = async (formData) => {
        const from = 'bulkUploadInventory'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'bulkuploadinventory', formData, 'formdata'),
            ])
            if (res.data.status === 'true') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: 'Product posted successfully',
                        responseData: res.data.lot_id,
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const imageBulkUpload = async (formData) => {
        const from = 'bulkUploadImage'
        const [res] = await Promise.all([
            apiCall('post', 'seller_side_lot_image_bulk_upload', formData, '', 'mobileapi'),
        ])
        try {
            //console.log(res.data.success, 'this is the responcerrom iamge upload')
            if (res.data.success) {
                resp.commonResponse({ status: 'success', message: res.data.message }, from)
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
            return res
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const Edit_invoice = async (formData) => {
        const from = 'edit_invoice'
        const [res] = await Promise.all([
            apiCall('post', 'update_invoice_details', formData, '', ''),
        ])
        try {
            if (res.data.status) {
                resp.commonResponse(
                    {
                        status: 'success',
                        message:
                            formData.status == 'shipupdate'
                                ? 'Your Response Updated To Seller. Please Wait.'
                                : formData.status == 'email'
                                ? 'Mail Sent To Buyer Successfully'
                                : 'Invoice Updated Successfully',
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: 'Error from api' }, from)
            }
            return res
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const getUpcomingList = async (formData) => {
        const from = 'getUpcomingList'
        const [res] = await Promise.all([apiCall('post', 'get_upcoming_list', formData, '', '')])
        try {
            if (res.data.success) {
                dispatch({
                    type: UPCOMING_LIST,
                    payload: res.data.result,
                })
            } else {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: '',
                })
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const deleteItem = async (formData) => {
        const from = 'deleteItem'
        const [res] = await Promise.all([apiCall('post', 'removeproductdetails', formData, '', '')])
        try {
            if (res.data.message === 'success') {
                resp.commonResponse({ status: 'success', message: 'Deleted Successfully!' }, from)
            } else {
                resp.commonResponse({ status: 'error', message: 'Try again later!' }, from)
            }
            return res
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const editReserve = async (formData) => {
        const from = 'editReserve'
        const [res] = await Promise.all([apiCall('post', 'edit_reserve_price', formData, '', '')])
        try {
            if (res.data.status === 'yes') {
                resp.commonResponse({ status: 'success', message: 'Edited Successfully!' }, from)
            } else {
                resp.commonResponse({ status: 'error', message: 'Try again later!' }, from)
            }
            return res
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const buynow_to_auction = async (formData) => {
        const from = 'buynow_to_auction'
        const [res] = await Promise.all([apiCall('post', 'buynow-to-auction', formData, '', '')])
        try {
            if (res.data.success) {
                resp.commonResponse(
                    { status: 'success', message: 'Product Moved To Auction Successfully!' },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: 'Try again later!' }, from)
            }
            return res
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const processPosBuynow = async (formData) => {
        const from = 'processPosBuynow'
        const [res] = await Promise.all([apiCall('post', 'process-pos-buynow', formData, '', '')])
        try {
            if (res.data.success) {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: 'Payment Initiated!',
                        responseData: {
                            payment_intent_id: res.data.payment_intent_id,
                            invoice_id: res.data.invoice_id,
                        },
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: 'Try again later!' }, from)
            }
            return res
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const completePosBuynow = async (formData) => {
        formData.test = 1
        const from = 'completePosBuynow'
        const [res] = await Promise.all([apiCall('post', 'complete-pos-buynow', formData, '', '')])
        try {
            if (res.data.success) {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: 'Payment Completed!',
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: 'Try again later!' }, from)
            }
            return res
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const processPosAuction = async (formData) => {
        const from = 'processPosAuction'
        const [res] = await Promise.all([apiCall('post', 'process-pos-auction', formData, '', '')])
        try {
            if (res.data.success) {
                resp.commonResponse(
                    {
                        status: 'success',
                        message:
                            formData.pos_type === 'process'
                                ? 'Payment Initiated!'
                                : 'Payment Completed!',
                        responseData:
                            formData.pos_type === 'process'
                                ? {
                                      payment_intent_id: res.data.payment_intent_id,
                                      invoice_id: res.data.invoice_id,
                                  }
                                : { complete: 1 },
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: 'Try again later!' }, from)
            }
            return res
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const update_shipping_details = async (formData) => {
        const from = 'update_shipping_details'
        const [res] = await Promise.all([
            apiCall('post', 'update_shipping_details', formData, '', ''),
        ])
        try {
            if (res.data.success) {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: 'Successfully Updated!',
                    },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: 'Try again later!' }, from)
            }
            return res
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const updatelocalpickup = async (formData) => {
        const from = 'updatelocalpickup'
        const [res] = await Promise.all([apiCall('post', 'updatelocalpickup', formData, '', '')])
        try {
            if (res.data.success === 'yes') {
                resp.commonResponse(
                    {
                        status: 'success',
                        message: 'Successfully Updated!',
                    },
                    from,
                )
            } else {
                resp.commonResponse(
                    { status: 'error', message: res.data?.message || 'Try again later!' },
                    from,
                )
            }
            return res
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const insertProductView = async (formData) => {
        apiCall('post', 'insertProductViewed', formData, '', '')
    }

    const insert_make_proposels = async (formData) => {
        const from = 'insert_make_proposales'
        const [res] = await Promise.all([apiCall('post', 'insert_make_an_offer', formData, '', '')])
        try {
            if (res.data.success) {
                resp.commonResponse(
                    {
                        status: 'success',
                        message:
                            'Successfully Offer Updated.Please Check Offer Status in Dashboard.',
                    },
                    from,
                )
            } else {
                resp.commonResponse(
                    { status: 'error', message: res.data?.message || 'Try again later!' },
                    from,
                )
            }
            return res
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getAllbrands = async (formData) => {
        const from = 'get_all_brands'
        const [res] = await Promise.all([apiCall('post', 'fetchBrands', formData, '', '')])
        try {
            if (res.data.success) {
                dispatch({
                    type: GET_ALL_BRAND_DETAILS,
                    payload: res.data.brands,
                })
            } else {
                resp.commonResponse(
                    { status: 'error', message: res.data?.message || 'Try again later!' },
                    from,
                )
            }
            return res
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getAlldynamic = async (formData) => {
        const from = 'get_all_dynamic_fields'
        const [res] = await Promise.all([apiCall('post', 'fetchdynamic_field', formData, '', '')])
        try {
            if (res.data.success) {
                dispatch({
                    type: GET_ALL_DYNAMIC_FIELDS,
                    payload: res.data.fields,
                })
            } else {
                resp.commonResponse(
                    { status: 'error', message: res.data?.message || 'Try again later!' },
                    from,
                )
            }
            return res
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const lotauctionreg = async (formData, setRegistered) => {
        const from = 'lot_auction_reg'
        const [res] = await Promise.all([apiCall('post', 'privateauctionreg', formData, '', 'api')])
        if (res.data && res.data.status === 'success') {
            setRegistered(1)
            resp.commonResponse(
                { status: 'success', message: 'Auction Register Successfully!' },
                from,
            )
        } else {
            resp.commonResponse(
                { status: 'error', message: res?.data?.response || 'Error In Registration.' },
                from,
            )
        }
    }
    const productMessage = async (formData) => {
        const [res] = await Promise.all([apiCall('post', 'product_message', formData, '', '')])
        if (res.data.success) {
            return true
        }
        return false
    }
    const getProductMessages = async (formData) => {
        const [res] = await Promise.all([apiCall('post', 'get_product_messages', formData, '', '')])
        if (res.data.success) {
            return {
                success: true,
                data: res.data.live_chat.map((val) => {
                    return val
                }),
            }
        }
        return { success: false }
    }
    const messageLike = async (formData) => {
        const [res] = await Promise.all([apiCall('post', 'insert_like', formData, '', '')])
    }

    return (
        <ProductContext.Provider
            value={{
                // buyer_allproducts: state.buyer_allproducts,
                la_sellers: state.la_sellers,
                search_allproducts: state.search_allproducts,
                search_allauctionproducts: state.search_allauctionproducts,
                search_allsimilarproducts: state.search_allsimilarproducts,
                search_allauctions: state.search_allauctions,
                edit_product: state.edit_product,
                all_active_users: state.all_active_users,
                edit_auction: state.edit_auction,
                invoice_details: state.invoice_details,
                group_invoice_details: state.group_invoice_details,
                auction_lots: state.auction_lots,
                buyer_activeproducts: state.buyer_activeproducts,
                buyer_wonproducts: state.buyer_wonproducts,
                buyer_lostproducts: state.buyer_lostproducts,
                seller_allproducts: state.seller_allproducts,
                seller_allauctions: state.seller_allauctions,
                responseStatus: state.responseStatus,
                shipping_rates: state.shipping_rates,
                allProductsViewed: state.allProductsViewed,
                categories: state.categories,
                sub_categories: state.sub_categories,
                seller_dashboard_data: state.seller_dashboard_data,
                upcoming_list: state.upcoming_list,
                all_unpaid_users: state.all_unpaid_users,
                pos_buynow_products: state.pos_buynow_products,
                brandsproduct: state.brandsproducts,
                dynamicproducts: state.dynamicproducts,
                productLocalState,
                currency,
                updatelocalpickup,
                update_shipping_details,
                setCurrency,
                setState,
                processPosAuction,
                completePosBuynow,
                processPosBuynow,
                deleteItem,
                getUpcomingList,
                insertProductView,
                getLotList,
                clearResponse,
                getAllSellerProducts,
                getAllSellerAuctions,
                getAllSearchProducts,
                getAllSearchAuctions,
                getAllSimilarProducts,
                getActiveBidProducts,
                getWonBidProducts,
                getShippingRates,
                getLostBidProducts,
                getEditProduct,
                getOverAllCategories,
                getSubCategories,
                getActiveUsers,
                editProduct,
                postProduct,
                bulkUploadInventory,
                getEditAuction,
                postAuction,
                changeMarketStatus,
                updateProjectDynamic,
                updateBuyNowDynamic,
                postShipping,
                postLocalPickup,
                buyNowProduct,
                getNewAuction,
                getInvoice,
                getInvoiceNew,
                getCheckoutAuction,
                addWatchlist,
                removeWatchlist,
                report,
                getla_sellers,
                productViewed,
                getProduct_viewed_all,
                get_seller_dashboard,
                imageBulkUpload,
                Edit_invoice,
                relist,
                repost,
                clearResponse_invoice_details,
                editReserve,
                buynow_to_auction,
                getAllUnpaidUsers,
                getPosBuynowProducts,
                clearResponse_search_details,
                insert_make_proposels,
                getAllbrands,
                getAlldynamic,
                lotauctionreg,
                productMessage,
                getProductMessages,
                messageLike,
                clearEditProduct,
            }}
        >
            {props.children}
        </ProductContext.Provider>
    )
}

export default ProductState
