import * as Yup from 'yup'
const validation1 = Yup.object({
    category_id: Yup.string().required('Required!'),
    subcategory: Yup.string().required('Required!'),
    customfield1: Yup.string().required('Required!'),
    customfield2: Yup.string().required('Required!'),
    customfield3: Yup.string().required('Required!'),
    customfield4: Yup.string().required('Required!'),
    customfield6: Yup.string().required('Required!'),
    radcondition: Yup.string().required('Required!'),
    protitle: Yup.string().required('Required!'),
    description: Yup.string().required('Required!'),
})
const validation2 = Yup.object({
    inventory_date: Yup.date().typeError('000').nullable('Incorrect Start Date'),
    game_theme: Yup.string().required('Required!'),
})
const validation4 = Yup.object({
    // mprice: Yup.number()
    //     .typeError('Number Only!')
    //     .positive('Should be greater than 1')
    //     .required('Required!'),
    bprice: Yup.number()
        .typeError('Number Only!')
        .positive('Should be greater than 1')
        // .lessThan(Yup.ref('mprice'), 'Should be lesser than the Retail price')
        .required('Required!'),
    qty: Yup.number()
        .typeError('Number Only!')
        .positive('Should be greater than 1')
        .required('Required!'),
    customfield5: Yup.string().required('Required!'),
    startdate: Yup.string().required('Incorrect Start Date').nullable(),
    enddate: Yup.string().required('Incorrect End Date').nullable(),
})

const validation5 = Yup.object({
    address: Yup.string().when('hasshipping', {
        is: '2' || 2,
        then: Yup.string().required('Required!'),
    }),
    location: Yup.string().when('hasshipping', {
        is: '2' || 2,
        then: Yup.string().required('Required!'),
    }),
    state: Yup.string().when('hasshipping', {
        is: '2' || 2,
        then: Yup.string().required('Required!'),
    }),
    city: Yup.string().when('hasshipping', {
        is: '2' || 2,
        then: Yup.string().required('Required!'),
    }),
    zipcode: Yup.string().when('hasshipping', {
        is: '2' || 2,
        then: Yup.string().when('location', {
            is: '38',
            then: Yup.string()
                .matches(
                    /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i,
                    'Invalid Zipcode',
                )
                .required('Required'),
            otherwise: Yup.string()
                .matches(/\b\d{5}\b/g, 'Invalid Zipcode!')
                .required('Required'),
        }),
    }),
    shippingcost: Yup.number()
        .typeError('Number Only!')
        .when('hasshipping', {
            is: 1,
            then: Yup.number()
                .typeError('Number Only!')
                .required('Required!')
                .positive('Should be greater than 1'),
        }),
})
const allValidations = {
    validation1,
    validation2,
    validation4,
    validation5,
}

export const getValidationSchema = (index) => allValidations[`validation${index}`]
