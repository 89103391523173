import React from 'react'
import { fade, makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import './dashboard.css'

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    dashboardheader: {
        background: '#f5f5f5',
        boxShadow: 'none',
        height: 64,
        marginTop: 30,
    },
    toolbar: {
        background: '#f5f5f5',
        maxWidth: '1280px',
        width: '100%',
        margin: '0 auto',
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        margin: 0,
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}))

const DashboardHeader = ({ title, subtitle, savedcards, seller, invoices }) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)

    const isMenuOpen = Boolean(anchorEl)
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

    // function handleProfileMenuOpen(event) {
    //   setAnchorEl(event.currentTarget);
    // }

    function handleMobileMenuClose() {
        setMobileMoreAnchorEl(null)
    }

    function handleMenuClose() {
        setAnchorEl(null)
        handleMobileMenuClose()
    }

    function handleMobileMenuOpen(event) {
        setMobileMoreAnchorEl(event.currentTarget)
    }

    return (
        <div className={classes.grow + ' dashboardMenu'}>
            <AppBar position="static" className={classes.dashboardheader}>
                <Toolbar className={classes.toolbar}>
                    <div className="dbmhead db-head-xs">
                        <img src="/image/dashboard.svg" alt="" /> DASHBOARD
                    </div>
                    <div className={classes.grow} />
                    <ul className={classes.sectionDesktop}>
                        <MenuItem className="dbmlinks">
                            <NavLink activeClassName="active" to="/dashboard/mybids">
                                My Bids
                            </NavLink>
                        </MenuItem>
                        <MenuItem className="dbmlinks">
                            <NavLink activeClassName="active" to="/dashboard/profile">
                                Profile
                            </NavLink>
                        </MenuItem>
                        <MenuItem className="dbmlinks">
                            <NavLink to="/dashboard/favorites" activeClassName="active">
                                Favorites
                            </NavLink>
                        </MenuItem>
                        {global?.pluginConfiguration?.buyers_assurance?.enable == 1 && (
                            <MenuItem className="dbmlinks">
                                <NavLink to="/dashboard/subscription" activeClassName="active">
                                    Subscription
                                </NavLink>
                            </MenuItem>
                        )}
                        {invoices && (
                            <MenuItem className="dbmlinks">
                                <NavLink to="/dashboard/transactions" activeClassName="active">
                                    Invoices
                                </NavLink>
                            </MenuItem>
                        )}
                        <MenuItem className="dbmlinks">
                            <NavLink to="/dashboard/cards" activeClassName="active">
                                Saved Cards
                            </NavLink>
                        </MenuItem>
                        <MenuItem className="dbmlinks">
                            <NavLink to="/dashboard/preference" activeClassName="active">
                                Preference
                            </NavLink>
                        </MenuItem>
                        {global.pluginConfiguration?.ticketing?.enable == 1 && (
                            <MenuItem className="dbmlinks">
                                <NavLink to="/dashboard/mytickets" activeClassName="active">
                                    My Tickets
                                </NavLink>
                            </MenuItem>
                        )}
                        {global.pluginConfiguration?.referral?.enable == 1 ? (
                            <MenuItem className="dbmlinks">
                                <NavLink to="/referral" activeClassName="active">
                                    Referral
                                </NavLink>
                            </MenuItem>
                        ) : null}
                    </ul>
                </Toolbar>
            </AppBar>
        </div>
    )
}

export default withRouter(DashboardHeader)
