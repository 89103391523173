import { Button, Tooltip } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import SecondaryButton from '../../../Product/components/atoms/SecondaryButton'
import CartContext from '../../../Product/context/cart/cartContext'
import AuthContext from '../../../Product/context/auth/authContext'
import { handleRedirectInternal } from '../../../Product/common/components'
import { useHistory } from 'react-router-dom'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'

const AddToCart = ({ price, id, qty, addLabel, removeLabel, customLabel }) => {
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const { user, isAuthenticated } = authContext
    const cartContext = useContext(CartContext)
    const { buyer_cartitems, addToCart, removeFromCart, responseStatus, getUserCart } = cartContext
    const [alreadyInCart, setAlreadyInCart] = useState(false)
    const handleClick = async () => {
        if (isAuthenticated) {
            if (alreadyInCart) {
                return removeFromCart({ id })
            }
            addToCart({ id, qty: qty ? qty : 1 })
        } else {
            handleRedirectInternal(history, 'login')
        }
    }
    useEffect(() => {
        setAlreadyInCart(
            buyer_cartitems.results.find((val) => parseInt(val.product_id) === parseInt(id)),
        )
    }, [buyer_cartitems.results])
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'removeFromCart') {
                if (responseStatus.status === 'success') {
                    getUserCart()
                }
            }
        }
    }, [responseStatus])
    return (
        <>
            {customLabel ? (
                alreadyInCart ? (
                    <Tooltip title="Remove from cart">
                        <Button className="addToCartBtn inCart" onClick={handleClick}>
                            {removeLabel}
                        </Button>
                    </Tooltip>
                ) : (
                    <Tooltip title="Add to cart">
                        <Button className="addToCartBtn " onClick={handleClick}>
                            {addLabel}
                        </Button>
                    </Tooltip>
                )
            ) : (
                <PrimaryButton onClick={handleClick}>
                    {alreadyInCart
                        ? 'Remove From Cart'
                        : `Buy Now For $${parseFloat(price).toUSFormat()}`}
                </PrimaryButton>
            )}
        </>
    )
}

export default AddToCart
