import { IconButton } from '@material-ui/core'
import React, { useEffect, useState, useContext } from 'react'
import moment from 'moment'
import { socket } from '../../../Product/common/socket'
import BuyerContext from '../../../Product/context/buyer/buyerContext'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import { useTranslation } from 'react-i18next'
const MessageBody = ({ chatHistory, chatUser, backImage, project_id, chat_display }) => {
    const buyerContext = useContext(BuyerContext)
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const { user } = authContext
    const { listAllBuyers } = buyerContext
    const { t } = useTranslation()
    const [messageValue, setMessageValue] = useState('')
    const [chatMessages, setChatMessages] = useState([])
    const [loggedUser, setLoggedUser] = useState({})

    const sendMessage = () => {
        if (!messageValue.trim()) {
            return false
        }
        var msg = {
            from_id: loggedUser.id,
            to_id: chatUser.id,
            message: messageValue.trim(),
            date_sent: moment(new Date()).format('DD/MM/YYYY hh:mm a'),
            project_id,
        }
        socket.emit('insertMessages', msg)
        setAlert('Message sent successfully', 'success')
        setMessageValue('')
    }
    const messageHandler = (data) => {
        if (data.to_id == loggedUser.id) {
            listAllBuyers({
                limit_value: 0,
                search_key: ' ',
                user_role: project_id ? undefined : 'both',
                project_id,
            })
        }
        if (data.from_id == loggedUser.id || data.from_id == chatUser.id) {
            setChatMessages((prev) => [...prev, data])
            let msgBody = document.getElementById('msgInrBody')
            msgBody.scrollTop = msgBody.scrollHeight
        }
    }
    useEffect(() => {
        if (user) {
            setLoggedUser(user)
        }
    }, [user])

    useEffect(() => {
        if (chatHistory.length) {
            setChatMessages(chatHistory)
            let msgBody = document.getElementById('msgInrBody')
            msgBody.scrollTop = msgBody.scrollHeight
        } else {
            setChatMessages([])
        }
    }, [chatHistory])
    useEffect(() => {
        if (Object.keys(loggedUser).length) {
            socket.on('insertMessages_result', (data) => {
                messageHandler(data)
            })
        }

        return () => {
            socket.off('insertMessages_result', (data) => {
                return data
            })
        }
    }, [loggedUser])

    return (
        <>
            <div className="topCntnr">
                <div className="prflWrpr">
                    {Object.keys(chatUser).length
                        ? chatUser.first_name?.charAt(0) + chatUser.last_name?.charAt(0)
                        : ''}
                </div>
                <p className="m-0">
                    {Object.keys(chatUser).length
                        ? chatUser.first_name + ' ' + chatUser.last_name
                        : 'Kindly select a user to chat!'}
                </p>
            </div>
            <div className={`messageBody`}>
                {backImage != '' && backImage != undefined && (
                    <img className="backDrop" src={backImage} />
                )}
                <div className="msgInrBody" id="msgInrBody">
                    {chat_display ? (
                        <>
                            {chatMessages.map((value) => (
                                <div
                                    className={`chatBblWrpr ${
                                        value.to_id == chatUser.id
                                            ? 'justify-content-end sndWrpr'
                                            : 'justify-content-start'
                                    }`}
                                    key={value.id}
                                >
                                    {value.to_id == chatUser.id ? (
                                        <small className="mr-2">
                                            {moment(value.date_sent).isValid()
                                                ? moment(value.date_sent).format(
                                                      'DD/MM/YYYY h:mm a',
                                                  )
                                                : value.date_sent}
                                        </small>
                                    ) : (
                                        ''
                                    )}
                                    <div className="chatBubble">
                                        <pre>{value.message}</pre>
                                    </div>
                                    {value.to_id != chatUser.id ? (
                                        <small className="ml-2">
                                            {moment(value.date_sent).isValid()
                                                ? moment(value.date_sent).format(
                                                      'DD/MM/YYYY h:mm a',
                                                  )
                                                : value.date_sent}
                                        </small>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            ))}
                        </>
                    ) : (
                        ''
                    )}
                </div>
                <div className="msgIptWrpr topCntnr">
                    <textarea
                        placeholder={t('type_message_here')}
                        onChange={(e) => setMessageValue(e.target.value)}
                        value={messageValue}
                    />
                    <IconButton className="sndButn" onClick={sendMessage}>
                        <span className="material-icons">send</span>
                    </IconButton>
                </div>
            </div>
        </>
    )
}

export default MessageBody
