/*eslint-disable*/
import React, { useEffect, useState, useContext, useRef } from 'react'
import './cart.css'
import _ from 'lodash'
import Nodata from '../../../Component/NoData/nodata'
import { percentage, getValidProduct, getvaliditems } from '../../../utils/commonFunctions'
import { useHistory, Link } from 'react-router-dom'
import CartContext from '../../../Product/context/cart/cartContext'
import AuthContext from '../../../Product/context/auth/authContext'
import AlertContext from '../../../Product/context/alert/alertContext'
import ProductContext from '../../../Product/context/product/productContext'
import PosContext from '../../../Product/context/plugin/pos/posContext'
import { currencyFormat, handleRedirectInternal, mapData } from '../../../Product/common/components'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import { messageHandleBuynow } from '../../../Product/common/socketHandler'
import { socket, socketAuction } from '../../../Product/common/socket'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import BuyerContext from '../../../Product/context/buyer/buyerContext'
import { apiCall } from '../../../Product/common/api'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import UserActivity from '../UserActivity'
import { useTranslation } from 'react-i18next'
import Qtyinput from '../../../Product/common/Qtyinput'
import Timer from '../../../Product/common/TimerBuynow'
const CartComponent = (props) => {
    const cartContext = useContext(CartContext)
    const authContext = useContext(AuthContext)
    const buyerContext = useContext(BuyerContext)
    const [viewCartItems, setViewCartItems] = useState([])
    const [cart_error, setCartError] = useState(false)
    const [readers, setReaders] = useState([])
    const [remove_id, setRemoveid] = useState(0)
    const { t } = useTranslation()
    const alertContext = useContext(AlertContext)
    const [total, setTotal] = useState(0)
    const [totalBuyersPremium, setTotalBuyersPremium] = useState(0)
    const [shippingFee, setShippingFee] = useState(0)
    const [transactionFee, setTransactionFee] = useState(0)
    const [deposit, setDeposit] = useState(0)
    const [salesTax, setSalesTax] = useState(0)
    const [taxData, setTaxData] = useState(0)
    const [fee, setFee] = useState(0)
    const [shippingpos, setShippingpos] = useState(0)
    const pos = new URLSearchParams(window.location.search.substring(1)).get('pos')
    const type = new URLSearchParams(window.location.search.substring(1)).get('type')
    const id = new URLSearchParams(window.location.search.substring(1)).getAll('id')
    const [total_error, setTotalerror] = useState('')
    const [intervalFunction, setIntervalFunction] = useState('')
    const [allUsers, setAllUsers] = useState([])
    const [paymentId, setPaymentId] = useState({
        payment_intent_id: '',
        invoice_id: [],
    })
    const [posLoading, setPosLoading] = useState(false)

    let history = useHistory()
    const { user } = authContext
    const {
        addToCart,
        buyer_cartitems,
        getUserCart,
        removeFromCart,
        updateFromCart,
        responseStatus,
    } = cartContext
    const { setAlert, clearAlert } = alertContext
    const {
        processPosBuynow,
        completePosBuynow,
        getActiveUsers,
        all_active_users,
        getCheckoutAuction,
        invoice_details,
        processPosAuction,
        responseStatus: response,
    } = useContext(ProductContext)
    const { allReaders, all_readers, payment_status, paymentStatus } = useContext(PosContext)
    const { get_ship_station_amount, shipping_rate_calculation, clear_ship_station_amount } =
        buyerContext
    // useEffect(() => {
    //     getUserCart()
    // }, [])
    useEffect(() => {
        if (buyer_cartitems.results) {
            setViewCartItems(buyer_cartitems.results)
        }
    }, [buyer_cartitems.results])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (viewCartItems.length && user && global.pluginConfiguration?.avalara?.enable) {
            viewCartItems.map(async (val, index) => {
                console.log(val.taxStatus, 'val.taxStatus')
                if (val.cart_sales_tax == 0 && val.taxStatus != 'success') {
                    // var seller_zipcode = '',
                    //     address = ''
                    // if (val.custom_field_8) {
                    //     seller_zipcode = JSON.parse(val.custom_field_8)?.zipcode
                    //         ? JSON.parse(val.custom_field_8).zipcode
                    //         : ''
                    //     address = JSON.parse(val.custom_field_8)?.address
                    //         ? JSON.parse(val.custom_field_8).address
                    //         : ''
                    // }
                    var send_data = {
                        site_id: global.storeDetails?.site_id ? global.storeDetails.site_id : '',
                        user_id: user?.id,
                        price: val.bprice,
                        buyer_address: user?.address,
                        buyer_city: user?.city,
                        buyer_state: user?.state,
                        buyer_country: user?.country == '224' ? 'United States' : user?.country,
                        buyer_zip: user?.zip,
                        seller_address: val.sel_address,
                        seller_city: val.sell_city,
                        seller_state: val.sell_state,
                        seller_country:
                            val.sell_country == '224' ? 'United States' : val.sell_country,
                        seller_zip: val.sell_zip,
                        qty: val.qty,
                        cart_id: val.cart_id,
                        // site_id: 'AS0513',
                        // user_id: '15831',
                        // price: '100',
                        // buyer_address: '745 Grier Drive Suite 181',
                        // buyer_city: 'Richardson',
                        // buyer_state: 'Nevada',
                        // buyer_country: 'United States',
                        // buyer_zip: '89129',
                        // seller_address: '745 Grier Drive Suite 181',
                        // seller_city: 'Richardson',
                        // seller_state: 'Nevada',
                        // seller_country: 'United States',
                        // seller_zip: '89129',
                        // qty: '1',
                    }
                    const [res_ava] = await Promise.all([
                        apiCall(
                            'post',
                            'getOrderTaxCalculator',
                            send_data,
                            {},
                            'plugin/avalara/service/onBoarding',
                        ),
                    ])
                    console.log('reponse avalara', res_ava)
                    if (res_ava?.data?.status === 'success') {
                        viewCartItems[index].sales_tax = user.tax_excempt
                            ? 0
                            : res_ava?.data?.data?.message?.totalTax
                            ? res_ava.data.data.message.totalTax
                            : 0

                        //if (parseInt(index) == 0) {
                        var view_cartitems = viewCartItems
                        view_cartitems[index].total_payable_amount =
                            parseFloat(view_cartitems[index].total_payable_amount) +
                            parseFloat(res_ava.data.data.message.totalTax)
                        view_cartitems[index].cart_sales_tax = res_ava.data.data.message.totalTax
                        view_cartitems[index].taxStatus = 'success'
                        setViewCartItems(view_cartitems)
                        setSalesTax(_.sumBy(viewCartItems, (x) => parseFloat(x.sales_tax)))
                    } else {
                        setTotalerror(
                            'Calculation error in salestax.please update your correct address details.',
                        )
                        setAlert(
                            'Calculation error in salestax.please update correct address details.',
                            'error',
                        )
                    }
                }
            })
        }
    }, [viewCartItems, user])

    useEffect(() => {
        if (viewCartItems.length) {
            setTotal(
                _.sumBy(viewCartItems, (x) => parseFloat(type === 'auction' ? x.amount : x.bprice)),
            )
            setShippingFee(
                _.sumBy(viewCartItems, (x) => parseFloat(x.localpickup == 0 ? x.shipping_fee : 0)),
            )
            setTransactionFee(_.sumBy(viewCartItems, (x) => parseFloat(x.transaction_fee)))
            setSalesTax(_.sumBy(viewCartItems, (x) => parseFloat(x.sales_tax)))
            setDeposit(_.sumBy(viewCartItems, (x) => parseFloat(x.deposit_amount)))
            setTotalBuyersPremium(
                _.sumBy(viewCartItems, (x) => parseFloat(x.buyers_premium + x.charge_fee)),
            )
            if (global.pluginConfiguration?.shipping?.enable && user) {
                viewCartItems.map(async (val, index) => {
                    if (
                        (parseInt(val.shipping_fee) == 0 || val.shipping_fee == '') &&
                        parseInt(val.hasshipping) &&
                        val.weight != 0 &&
                        val.height != 0 &&
                        val.length != 0 &&
                        val.width != 0
                    ) {
                        var seller_zipcode = ''
                        if (val.custom_field_8) {
                            seller_zipcode = JSON.parse(val.custom_field_8)?.zipcode
                                ? JSON.parse(val.custom_field_8).zipcode
                                : ''
                        }
                        var calculation_data = {
                            weight: val.weight,
                            height: val.height,
                            length: val.length,
                            width: val.width,
                            toPostalCode: user?.zip,
                            fromPostalCode: val.seller_zipcode
                                ? val.seller_zipcode
                                : seller_zipcode,
                            site_id: global.storeDetails?.site_id
                                ? global.storeDetails.site_id
                                : '',
                            project_id: val.project_id || 0,
                            toCountry: user?.country,
                        }
                        const [res] = await Promise.all([
                            apiCall(
                                'post',
                                'calculator',
                                calculation_data,
                                {},
                                'plugin/shipstation/service/getRate',
                            ),
                        ])
                        if (res.data?.data) {
                            var shipping_rate_calculation = res.data.data
                            if (shipping_rate_calculation.message) {
                                if (shipping_rate_calculation.message.shipping_status) {
                                    if (shipping_rate_calculation.message.shipping_status == 200) {
                                        // setShipping_amount(
                                        //     shipping_rate_calculation.message.rate[0]?.shipmentCost
                                        //         ? parseFloat(shipping_rate_calculation.message.rate[0].shipmentCost)
                                        //         : 0,
                                        // )
                                        ////console.log('pppppppppppppppppppppppp', index_pos)
                                        viewCartItems[index].shipping_fee =
                                            shipping_rate_calculation.message.rate[0]?.shipmentCost
                                                ? parseFloat(
                                                      shipping_rate_calculation.message.rate[0]
                                                          .shipmentCost,
                                                  )
                                                : 0

                                        //if (parseInt(index) == 0) {
                                        var view_cartitems = viewCartItems
                                        setViewCartItems(view_cartitems)
                                        setShippingFee(
                                            _.sumBy(view_cartitems, (x) =>
                                                parseFloat(x.localpickup == 0 ? x.shipping_fee : 0),
                                            ),
                                        )
                                        //}
                                    } else if (
                                        shipping_rate_calculation.message.shipping_status == 500
                                    ) {
                                        setTotalerror(
                                            'Product dimensional or Zip Code incorrect.Please check your Zip Code or contact seller.',
                                        )
                                        setAlert(
                                            'Product dimensional or Zip Code incorrect.Please check your Zip Code or contact seller.',
                                            'error',
                                        )
                                    } else {
                                        setTotalerror('Please Try Again Later!')
                                        setAlert('Please Try Again Later!', 'error')
                                    }
                                } else {
                                    setTotalerror('Please Try Again Later!')
                                    setAlert('Please Try Again Later!', 'error')
                                }
                            }
                            setTimeout(() => {
                                clearAlert()
                            }, 5000)
                        }
                    }
                })
            }
        }
    }, [viewCartItems, user])

    const redirect_chekout = () => {
        if (cart_error && global?.storeConfigration?.buy_now_with_qty?.value == 1) {
            return false
        }
        if (total_error) {
            setAlert(total_error, 'error')
        } else {
            if (pos == 1) {
                formik.handleSubmit()
                return false
            }
            if (user) {
                handleRedirectInternal(history, 'checkout/buynow')
            } else {
                handleRedirectInternal(history, 'login')
            }
            window.location.reload()
        }
    }
    const validationArray = Yup.object({
        email: Yup.string().trim().required('Required'),
        reader_id: Yup.string().trim().required('Required'),
    })
    const formik = useFormik({
        initialValues: {
            email: '',
            reader_id: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            const send_data = {
                cart_ids: JSON.stringify(viewCartItems.map((value) => value.cart_id)),
                ...values,
            }
            if (pos == 1 && paymentId.payment_intent_id === '') {
                setPosLoading(true)
                if (type === 'auction') {
                    processPosAuction({
                        invoice_id: id,
                        pos_type: 'process',
                        ...values,
                    })
                } else {
                    processPosBuynow(send_data)
                }
            }
            if (pos == 1 && paymentId.payment_intent_id) {
                setPosLoading(true)
                if (type === 'auction') {
                    processPosAuction({
                        pos_type: 'complete',
                        ...values,
                        ...paymentId,
                    })
                } else {
                    completePosBuynow({
                        ...send_data,
                        ...paymentId,
                    })
                }
            }
        },
    })
    const handleDelete = async (id) => {
        removeFromCart({ id })
    }

    useEffect(() => {
        if (remove_id > 0) {
            handleDelete(remove_id)
        }
    }, [remove_id])

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'removeFromCart') {
                if (responseStatus.status === 'success') {
                    getUserCart()
                }
            }
        }
    }, [responseStatus])
    useEffect(() => {
        if (pos == 1 && type === 'auction') {
            getCheckoutAuction({
                invoice_id: id,
            })
        }
    }, [pos, type])
    useEffect(() => {
        if (type === 'auction') {
            if (invoice_details.length > 0) {
                setViewCartItems(invoice_details)
                formik.setFieldValue('email', invoice_details[0].buyer_email)
            }
        }
    }, [invoice_details])
    useEffect(() => {
        if (response) {
            if (response.from === 'processPosBuynow' || response.from === 'processPosAuction') {
                if (response.status === 'success') {
                    if (response.data.complete) {
                        clearInterval(intervalFunction)
                        setPaymentId({
                            payment_intent_id: '',
                            invoice_id: '',
                        })
                        handleRedirectInternal(history, 'dashboard/mylots')
                    } else {
                        setPaymentId({
                            payment_intent_id: response.data.payment_intent_id,
                            invoice_id: response.data.invoice_id,
                        })
                    }
                } else {
                    setPosLoading(false)
                }
            }
            if (response.from === 'completePosBuynow') {
                setPosLoading(false)
                if (response.status === 'success') {
                    clearInterval(intervalFunction)
                    setPaymentId({
                        payment_intent_id: '',
                        invoice_id: '',
                    })
                    getUserCart()
                    handleRedirectInternal(history, 'dashboard/mylots')
                }
            }
        }
    }, [response])

    useEffect(() => {
        if (paymentId.payment_intent_id) {
            setIntervalFunction(
                setInterval(() => {
                    paymentStatus({
                        user_id: user?.id,
                        site_id: global?.storeDetails?.site_id,
                        paymentIntentId: paymentId.payment_intent_id,
                    })
                }, 2000),
            )
        }
    }, [paymentId.payment_intent_id])
    useEffect(() => {
        if (payment_status === 'succeeded') {
            formik.handleSubmit()
        }
    }, [payment_status])
    useEffect(() => {
        var temp_array = []
        all_active_users.map((val) => {
            if (val.card_paymentid && val.id != user?.id) {
                temp_array.push({
                    value: val.email,
                    show: val.email,
                })
            }
        })
        setAllUsers(temp_array)
    }, [all_active_users])
    const viewProductRef = useRef(viewCartItems)
    const userRef = useRef(user)
    useEffect(() => {
        viewProductRef.current = viewCartItems
        userRef.current = user
    })
    const handler = (message, type) => {
        messageHandleBuynow(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewCartItems,
            type,
            setRemoveid,
        )
    }
    useEffect(() => {
        socket.on('realclosedupdates', (data) => {
            //console.log('socket innnnnb')
            handler(data, 'realclosedupdatesbuynow')
            getUserCart()
        })
        // socket.on('bidAddtime', (data) => {
        //     handler(data, 'checkbuynowavailable')
        // })
        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdatesbuynow')
            })
            // socket.off('bidAddtime', (data) => {
            //     handler(data, 'realclosedupdatesbuynow')
            // })
        }
    }, [])

    useEffect(() => {
        if (pos == 1) {
            allReaders({ user_id: user?.id, site_id: global?.storeDetails?.site_id })
            getActiveUsers()
        }
    }, [])
    useEffect(() => {
        var temp_array = []
        all_readers.map((val) => temp_array.push({ value: val.id, show: val.label }))
        setReaders(temp_array)
    }, [all_readers])
    const fields = [
        {
            label: 'Customer Email *',
            placeholder: 'Select Customer Email',
            type: 'select',
            class: 'col-sm-6 col-12',
            options: allUsers,
            name: 'email',
            formik: formik,
            disabled: type === 'auction',
        },
        {
            label: 'Reader' + '*',
            placeholder: 'Select Reader',
            class: 'col-sm-6 col-12',
            type: 'select',
            options: readers,
            name: 'reader_id',
            formik: formik,
        },
    ]
    return (
        <>
            <UserActivity page="user_cart" />
            <div className="container pt-5">
                {/* <h2 className="mb-5 text-center">Cart Details</h2> */}
                {viewCartItems.length !== 0 ? (
                    <div className="row justify-content-center">
                        <div className="col-md-10">
                            {pos == 1 ? <div className="row">{mapData(fields)}</div> : null}
                            <div className="cart-table">
                                <div className="table-responsive">
                                    <table id="myTable" className="table">
                                        <thead>
                                            <tr>
                                                {global?.storeConfigration?.lot_sequence_order
                                                    ?.value == 1 ? (
                                                    <th>{'Lot #'}</th>
                                                ) : null}
                                                <th>{t('product')}</th>
                                                <th>{t('name')}</th>
                                                {global?.storeConfigration?.buy_now_with_qty
                                                    ?.value == 1 ? (
                                                    <th>Qty</th>
                                                ) : (
                                                    ''
                                                )}
                                                {global?.storeConfigration?.cart_added_expiry_minits
                                                    ?.value ? (
                                                    <th>End Timer</th>
                                                ) : (
                                                    ''
                                                )}
                                                {/* <th>Qty</th> */}
                                                <th>{t('price')}</th>
                                                {totalBuyersPremium != 0 ? (
                                                    <th>{t('buyer_premium')}</th>
                                                ) : null}
                                                {global.storeConfigration?.hide_transaction_fee
                                                    ?.value != 1 && <th>{t('transaction_fee')}</th>}
                                                {global.storeConfigration?.shipping_need?.value !=
                                                    1 && <th>{t('shipping_fee')}</th>}
                                                <th>{t('sales_tax')}</th>
                                                <th className="text-right">
                                                    <span id="amount" className="amount">
                                                        {t('amount')}
                                                    </span>{' '}
                                                </th>
                                                {type !== 'auction' && (
                                                    <th data-title="Action">{t('action')}</th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {viewCartItems.map((item, index) => (
                                                <>
                                                    <tr
                                                        key={index}
                                                        style={{
                                                            backgroundColor:
                                                                item.stock < item.qty
                                                                    ? '#ffd6cc'
                                                                    : '',
                                                        }}
                                                        className="cart-row"
                                                    >
                                                        {global?.storeConfigration
                                                            ?.lot_sequence_order?.value == 1 ? (
                                                            <td>
                                                                {item.lot_number
                                                                    ? item.lot_number
                                                                    : '-'}
                                                            </td>
                                                        ) : null}
                                                        <td className="ct-img">
                                                            <div className="product-img">
                                                                <div className="img-prdct">
                                                                    <LazyLoadImage
                                                                        effect="blur"
                                                                        placeholderSrc="/assets/svg/imageLoading.svg"
                                                                        height="100%"
                                                                        width="100%"
                                                                        src={`${
                                                                            item.content_head1 ==
                                                                                '0' ||
                                                                            item.store_id === 0
                                                                                ? process.env
                                                                                      .REACT_APP_BASE_URL +
                                                                                  'uploads/product/'
                                                                                : process.env
                                                                                      .REACT_APP_PRODUCT_IMAGE_URL
                                                                        }${item.is_avatar}`}
                                                                        onError={(e) =>
                                                                            (e.target.src = `${global?.storeDetails?.logoValue}`)
                                                                        }
                                                                        alt={item.title}
                                                                        className="co-img"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td data-title="Name">
                                                            <div>
                                                                {`${item.title} ${
                                                                    item.stock < item.qty
                                                                        ? '(SOLD OUT)'
                                                                        : ''
                                                                }`}
                                                            </div>
                                                        </td>
                                                        {global?.storeConfigration?.buy_now_with_qty
                                                            ?.value == 1 ? (
                                                            <td data-title="Qty">
                                                                <div>
                                                                    <span
                                                                        id="qty"
                                                                        className="amount"
                                                                    >
                                                                        <Qtyinput
                                                                            total_qty={item.stock}
                                                                            qty={item.qty}
                                                                            setCartError={
                                                                                setCartError
                                                                            }
                                                                            cart_id={item.cart_id}
                                                                            product_id={
                                                                                item.product_id
                                                                            }
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {global?.storeConfigration
                                                            ?.cart_added_expiry_minits?.value ? (
                                                            <td data-title="Timer">
                                                                <div>
                                                                    <span
                                                                        id="timer"
                                                                        className="amount"
                                                                    >
                                                                        <Timer
                                                                            date_added={
                                                                                new Date(
                                                                                    item.date_extra_field_1 ==
                                                                                    '0000-00-00 00:00:00'
                                                                                        ? item.date_added
                                                                                        : item.date_extra_field_1,
                                                                                )
                                                                            }
                                                                            getUserCartnew={() =>
                                                                                getUserCart()
                                                                            }
                                                                            date_closed={
                                                                                new Date(
                                                                                    new Date(
                                                                                        item.date_extra_field_1 ==
                                                                                        '0000-00-00 00:00:00'
                                                                                            ? item.date_added
                                                                                            : item.date_extra_field_1,
                                                                                    ).getTime() +
                                                                                        parseInt(
                                                                                            global
                                                                                                ?.storeConfigration
                                                                                                ?.cart_added_expiry_minits
                                                                                                ?.value
                                                                                                ? global
                                                                                                      .storeConfigration
                                                                                                      ?.cart_added_expiry_minits
                                                                                                      .value
                                                                                                : 0,
                                                                                        ) *
                                                                                            60000,
                                                                                )
                                                                            }
                                                                        />
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        ) : (
                                                            ''
                                                        )}
                                                        <td data-title="Price">
                                                            <div>
                                                                <span
                                                                    id="amount"
                                                                    className="amount"
                                                                >
                                                                    {currencyFormat(
                                                                        parseFloat(
                                                                            type === 'auction'
                                                                                ? item.amount
                                                                                : item.bprice,
                                                                        ),
                                                                        props.currency,
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </td>
                                                        {totalBuyersPremium != 0 ? (
                                                            <td data-title="Buyer's Premium">
                                                                <div>
                                                                    <span
                                                                        id="amount"
                                                                        className="amount"
                                                                    >
                                                                        {currencyFormat(
                                                                            parseFloat(
                                                                                item.buyers_premium +
                                                                                    item.charge_fee,
                                                                            ),
                                                                            props.currency,
                                                                        )}
                                                                    </span>{' '}
                                                                    - (
                                                                    {item.buyers_premium_percentage +
                                                                        item.charge_percentage}
                                                                    %)
                                                                </div>
                                                            </td>
                                                        ) : null}
                                                        {global.storeConfigration
                                                            ?.hide_transaction_fee?.value != 1 && (
                                                            <td data-title="Transaction Fee">
                                                                <div>
                                                                    <span
                                                                        id="amount"
                                                                        className="amount"
                                                                    >
                                                                        {currencyFormat(
                                                                            parseFloat(
                                                                                item.transaction_fee,
                                                                            ),
                                                                            props.currency,
                                                                        )}
                                                                    </span>
                                                                    {item.shipping_type == 1
                                                                        ? `(${item.shipping_percentage}%)`
                                                                        : ''}
                                                                </div>
                                                            </td>
                                                        )}
                                                        {global.storeConfigration?.shipping_need
                                                            ?.value != 1 && (
                                                            <td data-title="Shipping Fee">
                                                                {item.localpickup == 0 ? (
                                                                    <div>
                                                                        <span
                                                                            id="amount"
                                                                            className="amount"
                                                                        >
                                                                            {currencyFormat(
                                                                                parseFloat(
                                                                                    item.shipping_fee,
                                                                                ),
                                                                                props.currency,
                                                                            )}
                                                                        </span>{' '}
                                                                        {item.shipping_type == 1
                                                                            ? `(${item.shipping_percentage}%)`
                                                                            : ''}
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <span
                                                                            id="amount"
                                                                            className="amount"
                                                                        >
                                                                            {currencyFormat(
                                                                                parseFloat(0),
                                                                                props.currency,
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        )}
                                                        <td data-title="Sales Tax">
                                                            <div>
                                                                <span
                                                                    id="amount"
                                                                    className="amount"
                                                                >
                                                                    {currencyFormat(
                                                                        parseFloat(item.sales_tax),
                                                                        props.currency,
                                                                    )}
                                                                </span>{' '}
                                                                {global.pluginConfiguration?.avalara
                                                                    ?.enable
                                                                    ? ''
                                                                    : `(${item.sales_tax_percentage}%)`}
                                                            </div>
                                                        </td>
                                                        <td
                                                            data-title="Amount"
                                                            style={{ textAlign: 'right' }}
                                                            align="right"
                                                        >
                                                            <div>
                                                                <span
                                                                    id="amount"
                                                                    className="amount"
                                                                >
                                                                    {currencyFormat(
                                                                        parseFloat(
                                                                            type === 'auction'
                                                                                ? item.amount
                                                                                : item.total_payable_amount,
                                                                        ),
                                                                        props.currency,
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </td>
                                                        {type !== 'auction' && (
                                                            <td className="ct-action">
                                                                {' '}
                                                                <a
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() =>
                                                                        handleDelete(
                                                                            item.project_id,
                                                                        )
                                                                    }
                                                                >
                                                                    {' '}
                                                                    <span className="material-icons">
                                                                        delete
                                                                    </span>
                                                                </a>
                                                            </td>
                                                        )}
                                                    </tr>
                                                    {parseInt(item.auction) ? (
                                                        <tr className="cart-row">
                                                            {parseInt(item.wprice) <=
                                                            parseInt(item.bprice) ? (
                                                                <td className="carNote" colSpan={9}>
                                                                    <div className="cart-items-notes">
                                                                        * If the bid amount exceeds
                                                                        buy now price, you cannot
                                                                        buy this item. Please
                                                                        proceed to payment.
                                                                    </div>
                                                                </td>
                                                            ) : (
                                                                ''
                                                                // <div className="cart-items-notes">
                                                                //     * This item not available for buynow
                                                                //     now.
                                                                //     <b style={{ color: 'green' }}>
                                                                //         Please{' '}
                                                                //         <Link
                                                                //             to={
                                                                //                 '/search?product=' +
                                                                //                 item.project_id
                                                                //             }
                                                                //         >
                                                                //             click here
                                                                //         </Link>{' '}
                                                                //         to place bid and won.
                                                                //     </b>
                                                                // </div>
                                                            )}
                                                        </tr>
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="cartAmtPay float-right">
                                    <div className="caVal d-flex justify-content-between align-items-center">
                                        <p>{t('to_tal')} : </p>
                                        <span id="total" className="total">
                                            {currencyFormat(parseFloat(total), props.currency)}
                                        </span>
                                    </div>
                                    {totalBuyersPremium != 0 ? (
                                        <div className="caVal d-flex justify-content-between align-items-center">
                                            <p>{t('buyer_premium')} : </p>
                                            <span id="total" className="total">
                                                {currencyFormat(
                                                    parseFloat(totalBuyersPremium),
                                                    props.currency,
                                                )}
                                            </span>
                                        </div>
                                    ) : null}
                                    {global.storeConfigration?.hide_transaction_fee?.value != 1 && (
                                        <div className="caVal d-flex justify-content-between align-items-center">
                                            <p>{t('transaction_fee')} : </p>
                                            <span id="total" className="total">
                                                {currencyFormat(
                                                    parseFloat(transactionFee),
                                                    props.currency,
                                                )}
                                            </span>
                                        </div>
                                    )}
                                    {global.storeConfigration?.shipping_need?.value != 1 && (
                                        <div className="caVal d-flex justify-content-between align-items-center">
                                            <p>{t('shipping_fee')} : </p>
                                            <span id="total" className="total">
                                                {currencyFormat(
                                                    parseFloat(shippingFee),
                                                    props.currency,
                                                )}
                                            </span>
                                        </div>
                                    )}
                                    <div className="caVal d-flex justify-content-between align-items-center">
                                        <p>{t('sales_tax')} : </p>
                                        <span id="total" className="total">
                                            {currencyFormat(parseFloat(salesTax), props.currency)}
                                        </span>
                                    </div>
                                    {/* {user.tax_excempt === 0 && (
                                        <div className="caVal d-flex justify-content-between align-items-center">
                                            <p>TAX {taxData}% :</p>
                                            <span id="total" className="total">
                                                US$ {percentage(taxData, total).toUSFormat()}
                                            </span>
                                        </div>
                                    )}
                                    <div className="caVal d-flex justify-content-between align-items-center">
                                        <p>CONVENIENCE FEES {fee}% :</p>
                                        <span id="total" className="total">
                                            {` US$  ${percentage(fee, total).toUSFormat()}`}
                                        </span>
                                    </div> */}
                                    <div className="caVal d-flex justify-content-between align-items-center">
                                        <p>{t('grand_Total')} : </p>
                                        <span id="total" className="total">
                                            {currencyFormat(
                                                parseFloat(
                                                    total +
                                                        percentage(taxData, total) +
                                                        percentage(fee, total) +
                                                        totalBuyersPremium +
                                                        shippingFee +
                                                        transactionFee +
                                                        salesTax,
                                                ),
                                                props.currency,
                                            )}
                                        </span>
                                    </div>
                                    {deposit ? (
                                        <div className="caVal d-flex justify-content-between align-items-center">
                                            <p>DEPOSIT :</p>
                                            <span id="total" className="total">
                                                {currencyFormat(
                                                    parseFloat(deposit),
                                                    props.currency,
                                                )}
                                            </span>
                                        </div>
                                    ) : null}
                                    <div
                                        className="text-center cartActBtn"
                                        style={{ marginBottom: '45px' }}
                                    >
                                        {getValidProduct(viewCartItems) ? (
                                            <PrimaryButton
                                                onClick={() => redirect_chekout()}
                                                label={
                                                    pos == 1
                                                        ? `${
                                                              posLoading
                                                                  ? 'Loading...'
                                                                  : 'Make A Payment '
                                                          } `
                                                        : t('check_out')
                                                }
                                                disabled={posLoading || user?.status == 'suspended'}
                                            />
                                        ) : (
                                            <p
                                                className="pink-btn pbtn-sm"
                                                style={{ backgroundColor: '#ffd6cc' }}
                                            >
                                                Remove Sold Item From Cart{' '}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Nodata />
                )}
            </div>
        </>
    )
}

export default CartComponent
