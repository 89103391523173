import React, { useReducer } from 'react'
import CartContext from './cartContext'
import CartReducer from './cartReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import { RESPONSE_STATUS, GET_ALL_CART_PRODUCTS, CLEAR_RESPONSE } from './cartTypes'

const CartState = (props) => {
    const initialState = {
        responseStatus: null,
        buyer_cartitems: {
            results: [],
        },
    }

    const [state, dispatch] = useReducer(CartReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const addToCart = async (formData) => {
        const from = 'addToCart'
        if (global?.storeConfigration?.buy_now_with_qty?.value) {
            formData.qty = 1
        }
        try {
            const [res] = await Promise.all([apiCall('get', 'addtocart', formData)])
            if (res.data.status === 'yes') {
                resp.commonResponse(
                    { status: 'success', message: 'Successfully added to cart' },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const removeFromCart = async (formData) => {
        const from = 'removeFromCart'
        try {
            const [res] = await Promise.all([apiCall('get', 'removefromcart', formData)])
            if (res.data.success) {
                resp.commonResponse(
                    { status: 'success', message: 'Item removed successfully!' },
                    from,
                )
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getUserCart = async (formData = {}, fromVariable) => {
        const from = 'getUserCart'
        formData.auction_io = 1
        try {
            if (global.pluginConfiguration?.salestaxcity?.enable) {
                formData['city_sales_tax_enable'] = 1
            } else {
                formData['city_sales_tax_enable'] = 0
            }
            const [res] = await Promise.all([apiCall('get', 'getUserCart', formData)])
            if (res.data.success) {
                dispatch({
                    type: GET_ALL_CART_PRODUCTS,
                    payload: {
                        results: res.data.data.length ? res.data.data : [],
                        from: fromVariable,
                    },
                })
            } else {
                resp.commonResponse({ status: 'error', message: res.data.message }, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const updateFromCart = async (formData) => {
        const from = 'updateusercart'
        formData.auction_io = 1
        const [res] = await Promise.all([apiCall('post', 'updateCart', formData)])
        if (res.data.status == 'yes') {
            resp.commonResponse({ status: 'success', message: res.data.message }, from)
        } else {
            resp.commonResponse({ status: 'error', message: res.data.message }, from)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <CartContext.Provider
            value={{
                responseStatus: state.responseStatus,
                buyer_cartitems: state.buyer_cartitems,
                clearResponse,
                addToCart,
                removeFromCart,
                getUserCart,
                updateFromCart,
            }}
        >
            {props.children}
        </CartContext.Provider>
    )
}

export default CartState
