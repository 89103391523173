export const messageHandler = (data, product, user, setAlert, setViewProduct, type) => {
    if (user) {
        // console.log('user inside', user)
        let user_id = user.id
        const index = product.findIndex((s) => s.id === parseInt(data.id || data.project_id, 10))
        let productToChange = product[index]
        // console.log('data', data)
        if (index !== -1) {
            if (type === 'realclosedupdates') {
                if (data.usr !== '') {
                    if (user_id === parseInt(data.bpop_cbidder)) {
                        product[index] = {
                            ...productToChange,
                            market_status: 'sold',
                            bidtopstatus: 'won',
                            buynowamount: data.buynowamount,
                            cbidtext: 'Sold Price',
                            buynowed: data.bpop_cbidder,
                            buynowpaid: 0,
                            common_invoice: data.common_invoice,
                        }
                        setViewProduct([...product])
                    } else if (parseInt(productToChange.bid_or_not) > 0) {
                        product[index] = {
                            ...productToChange,
                            market_status: 'sold',
                            bidtopstatus: 'lost',
                            buynowamount: data.buynowamount,
                            cbidtext: 'Sold Price',
                            buynowed: data.bpop_cbidder,
                            buynowpaid: 0,
                        }
                        setViewProduct([...product])
                    } else {
                        product[index] = {
                            ...productToChange,
                            market_status: 'sold',
                            buynowamount: data.buynowamount,
                            cbidtext: 'Sold Price',
                        }
                        setViewProduct([...product])
                    }
                } else {
                    if (parseInt(productToChange.bid_or_not) > 0) {
                        product[index] = {
                            ...productToChange,
                            market_status: 'closed',
                            bidtopstatus: 'lost',
                        }
                        setViewProduct([...product])
                    } else {
                        product[index] = {
                            ...productToChange,
                            market_status: 'closed',
                        }
                        setViewProduct([...product])
                    }
                }
            } else if (type === 'bidAddtime') {
                let date_closed = product[index].date_closed
                if (parseInt(data.bpop_belowFive) === 1) {
                    date_closed = data.bpop_belowFiveIncrement
                }
                if (data.status === 'failed' && user_id === parseInt(data.bpop_cbidder)) {
                    setAlert(data.error, 'error')
                }
                if (data.status !== 'failed') {
                    let maxbidamt = product[index].maxbidamt
                    let cbidnot = product[index].cbidnot
                    // console.log(product[index], 'product[index]')
                    if (user_id === parseInt(data.bpop_cbidder)) {
                        setAlert('Bid placed successfully', 'success')
                        if (maxbidamt < parseFloat(data.bidAmount)) {
                            maxbidamt = data.bidAmount
                        }
                        if (data.bpop_bidcount == 1 && data.bpop_wprice == 1) {
                            maxbidamt = data.bpop_wprice_morehigh
                        }
                        if (data.bpop_orgwsprice && data.bpop_orgwsprice != '') {
                            maxbidamt = data.bpop_orgwsprice
                        }

                        if (parseInt(user_id) === parseInt(data.bpop_higher)) {
                            if (
                                parseInt(product[index].rprice || 0) <= parseInt(data.bpop_wprice)
                            ) {
                                if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                    data.bpop_bidstatus = `You are winning this item at $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()}. `
                                } else {
                                    data.bpop_bidstatus = `You are winning this item at $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()}. Your autoBid of $${parseFloat(
                                        data.bpop_wprice_morehigh,
                                    ).toUSFormat()} has been placed successfully.`
                                }
                                setAlert('You are winning this item!', 'success')
                            } else {
                                if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                    data.bpop_bidstatus = `Your bid of $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()} on this item placed, but reserve price not yet met.`
                                } else {
                                    data.bpop_bidstatus = `Your bid of $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()} and auto bid of $${parseFloat(
                                        data.bpop_wprice_morehigh,
                                    ).toUSFormat()} on this item placed, but reserve price not yet met.`
                                }
                                setAlert(
                                    'You are losing this item. Because of reserve price not yet met!',
                                    'error',
                                )
                            }
                        } else if (data.bpop_bidstatus.includes('losing')) {
                            data.bpop_bidstatus = `You've been outbid on this item. Current leading bid is $${parseFloat(
                                data.bpop_wprice,
                            ).toUSFormat()}`
                            setAlert("You've been outbid on this item.", 'error')
                        }

                        product[index] = {
                            ...productToChange,
                            next_bid: data.bpop_cuser_nextbid,
                            wprice: data.bpop_wprice,
                            latestbid: data.bpop_wprice,
                            bidtopstatus: data.bpop_bidstatus,
                            nobids: data.bpop_bidcount,
                            bidcount: data.bpop_bidcount,
                            incrementamt: data.bpop_cuser_increment,
                            bid_or_not: 1,
                            date_closed,
                            maxbidamt: maxbidamt,
                            latestbid_user: data.bpop_higher,
                            cbidtext: 'Current Bid',
                        }
                        setViewProduct([...product])
                    } else {
                        // console.log('coming inside 3333', cbidnot, productToChange.bid_or_not)
                        if (parseInt(productToChange.bid_or_not) > 0 || parseInt(cbidnot) > 0) {
                            // console.log('coming inside 55555', user_id, productToChange, data)
                            if (user_id === parseInt(data.bpop_higher)) {
                                // console.log('123123213', parseInt(productToChange.next_bid))
                                if (
                                    parseInt(productToChange.next_bid) < parseInt(data.bpop_nextbid)
                                ) {
                                    // console.log('faasdfsadf')

                                    if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                        data.bidtopstatus = `You are winning this item at $${parseFloat(
                                            data.bpop_wprice,
                                        ).toUSFormat()}.`
                                    } else {
                                        data.bidtopstatus = `You are winning this item at $${parseFloat(
                                            data.bpop_wprice,
                                        ).toUSFormat()}. Your autoBid of $${parseFloat(
                                            data.bpop_wprice_morehigh,
                                        ).toUSFormat()} has been placed successfully.`
                                    }
                                    product[index] = {
                                        ...productToChange,
                                        latestbid: data.bpop_wprice,
                                        nobids: data.bpop_bidcount,
                                        bidcount: data.bpop_bidcount,
                                        incrementamt: productToChange.incrementamt,
                                        next_bid: data.bpop_nextbid_org,
                                        wprice: data.bpop_wprice,
                                        bidtopstatus: data.bidtopstatus,
                                        bid_count: data.bpop_bidcount,
                                        latestbid_user: data.bpop_higher,
                                        date_closed,
                                    }
                                    setViewProduct([...product])
                                } else {
                                    if (
                                        parseInt(product[index].rprice || 0) <=
                                        parseInt(data.bpop_wprice)
                                    ) {
                                        if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                            data.bidtopstatus = `You are winning this item at $${parseFloat(
                                                data.bpop_wprice,
                                            ).toUSFormat()}.`
                                        } else {
                                            data.bidtopstatus = `You are winning this item at $${parseFloat(
                                                data.bpop_wprice,
                                            ).toUSFormat()}. Your autoBid of $${parseFloat(
                                                data.bpop_wprice_morehigh,
                                            ).toUSFormat()} has been placed successfully.`
                                        }
                                    } else {
                                        if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                            data.bidtopstatus = `Your bid of $${parseFloat(
                                                data.bpop_wprice,
                                            ).toUSFormat()} on this item placed, but reserve price not yet met.`
                                        } else {
                                            data.bidtopstatus = `Your bid of $${parseFloat(
                                                data.bpop_wprice,
                                            ).toUSFormat()} and auto bid of $${parseFloat(
                                                data.bpop_wprice_morehigh,
                                            ).toUSFormat()} on this item placed, but reserve price not yet met.`
                                        }
                                    }
                                    product[index] = {
                                        ...productToChange,
                                        latestbid: data.bpop_wprice,
                                        nobids: data.bpop_bidcount,
                                        bidcount: data.bpop_bidcount,
                                        incrementamt: productToChange.incrementamt,
                                        wprice: data.bpop_wprice,
                                        bidtopstatus: data.bidtopstatus,
                                        bid_count: data.bpop_bidcount,
                                        latestbid_user: data.bpop_higher,
                                        date_closed,
                                    }
                                    setViewProduct([...product])
                                }
                            } else {
                                product[index] = {
                                    ...productToChange,
                                    latestbid: data.bpop_wprice,
                                    nobids: data.bpop_bidcount,
                                    bidcount: data.bpop_bidcount,
                                    incrementamt: data.bpop_increment,
                                    next_bid: data.bpop_nextbid_org,
                                    wprice: data.bpop_wprice,
                                    latestbid_user: data.bpop_higher,
                                    bid_count: data.bpop_bidcount,
                                    bidtopstatus: `You've been outbid on this item. Current leading bid is $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()}`,
                                    bid_or_not: 1,
                                    date_closed,
                                }
                                setViewProduct([...product])
                            }
                        } else {
                            // console.log('coming inside 6777777', data)
                            product[index] = {
                                ...productToChange,
                                latestbid: data.bpop_wprice,
                                nobids: data.bpop_bidcount,
                                bidcount: data.bpop_bidcount,
                                incrementamt: data.bpop_increment,
                                next_bid: data.bpop_nextbid_org,
                                wprice: data.bpop_wprice,
                                bid_count: data.bpop_bidcount,
                                cbidtext: 'Current Bid',
                                date_closed,
                            }
                            setViewProduct([...product])
                        }
                    }
                    // getBidHistoryProduct({ product_id: project_id })
                }
            } else if (type === 'watchlistAdded') {
                product[index] = {
                    ...productToChange,
                    watchlistid: data.status === 'added' ? 1 : 0,
                }
                setViewProduct([...product])
            } else if (type === 'cancelbidemitted') {
                // console.log(data, 'cancellllllllllll')
                if (Object.keys(data).length) {
                    // console.log('current id vs new lot id', productToChange.id)

                    if (parseInt(data.project_id) === parseInt(productToChange.id)) {
                        product[index] = {
                            ...productToChange,
                            nobids: data.bpop_bidcount,
                            wprice: data.bpop_wprice,
                            next_bid:
                                data.bpop_bidcount == 0 ? data.bpop_wprice : data.bpop_nextbid,
                            latestbid: data.bpop_bidcount == 0 ? null : data.bpop_wprice,
                            bid_count: data.bpop_bidcount,
                        }
                        setViewProduct([...product])
                    }
                }
            }
        }
    }
}

export const messageHandlerSingle = (
    data,
    product,
    user,
    setAlert,
    setViewProduct,
    type,
    showAlert,
) => {
    if (user) {
        // console.log('user inside singlee', user)
        let user_id = user.id
        const index = product.id === parseInt(data.id || data.project_id, 10)
        let productToChange = product
        // console.log('data', data)
        if (index) {
            if (type === 'realclosedupdates') {
                if (data.usr !== '') {
                    if (user_id === parseInt(data.bpop_cbidder || data.usr)) {
                        product = {
                            ...productToChange,
                            market_status: 'sold',
                            bidtopstatus: 'won',
                            buynowamount: data.buynowamount,
                            cbidtext: 'Sold Price',
                            buynowed: data.bpop_cbidder,
                            buynowpaid: 0,
                            common_invoice: data.common_invoice,
                        }
                        setViewProduct(product)
                    } else if (parseInt(productToChange.bid_or_not) > 0) {
                        product = {
                            ...productToChange,
                            market_status: 'sold',
                            bidtopstatus: 'lost',
                            buynowamount: data.buynowamount,
                            cbidtext: 'Sold Price',
                            buynowed: data.bpop_cbidder,
                            buynowpaid: 0,
                        }
                        setViewProduct(product)
                    } else {
                        product = {
                            ...productToChange,
                            market_status: 'sold',
                            buynowamount: data.buynowamount,
                            cbidtext: 'Sold Price',
                        }
                        setViewProduct(product)
                    }
                } else {
                    if (parseInt(productToChange.bid_or_not) > 0) {
                        product = {
                            ...productToChange,
                            market_status: 'closed',
                            bidtopstatus: 'lost',
                        }
                        setViewProduct(product)
                    } else {
                        product = {
                            ...productToChange,
                            market_status: 'closed',
                        }
                        setViewProduct(product)
                    }
                }
            } else if (type === 'bidAddtime') {
                console.log('Socket Handler', data)
                let date_closed = product.date_closed
                if (parseInt(data.bpop_belowFive) === 1) {
                    date_closed = data.bpop_belowFiveIncrement
                }
                if (data.status === 'failed' && user_id === parseInt(data.bpop_cbidder)) {
                    setAlert(data.error, 'error')
                }
                if (data.status !== 'failed') {
                    let maxbidamt = product.maxbidamt
                    if (user_id === parseInt(data.bpop_cbidder)) {
                        setAlert('Bid placed successfully', 'success')
                        if (maxbidamt < parseFloat(data.bidAmount)) {
                            maxbidamt = data.bidAmount
                        }
                        if (data.bpop_bidcount == 1 && data.bpop_wprice == 1) {
                            maxbidamt = data.bpop_wprice_morehigh
                        }
                        if (data.bpop_orgwsprice && data.bpop_orgwsprice != '') {
                            maxbidamt = data.bpop_orgwsprice
                        }

                        if (parseInt(user_id) === parseInt(data.bpop_higher)) {
                            if (parseInt(product.rprice || 0) <= parseInt(data.bpop_wprice)) {
                                if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                    data.bpop_bidstatus = `You are winning this item at $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()}. `
                                } else {
                                    data.bpop_bidstatus = `You are winning this item at $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()}. Your autoBid of $${parseFloat(
                                        data.bpop_wprice_morehigh,
                                    ).toUSFormat()} has been placed successfully.`
                                }
                                setAlert('You are winning this item!', 'success')
                            } else {
                                if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                    data.bpop_bidstatus = `Your bid of $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()} on this item placed, but reserve price not yet met.`
                                } else {
                                    data.bpop_bidstatus = `Your bid of $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()} and auto bid of $${parseFloat(
                                        data.bpop_wprice_morehigh,
                                    ).toUSFormat()} on this item placed, but reserve price not yet met.`
                                }
                                setAlert(
                                    'You are losing this item. Because of reserve price not yet met!',
                                    'error',
                                )
                            }
                        } else if (data.bpop_bidstatus.includes('losing')) {
                            data.bpop_bidstatus = `You've been outbid on this item. Current leading bid is $${parseFloat(
                                data.bpop_wprice,
                            ).toUSFormat()}`
                            setAlert("You've been outbid on this item.", 'error')
                        }

                        product = {
                            ...productToChange,
                            next_bid: data.bpop_cuser_nextbid,
                            wprice: data.bpop_wprice,
                            latestbid: data.bpop_wprice,
                            bidtopstatus: data.bpop_bidstatus,
                            nobids: data.bpop_bidcount,
                            incrementamt: data.bpop_cuser_increment,
                            bid_or_not: 1,
                            date_closed,
                            maxbidamt: maxbidamt,
                            bid_count: data.bpop_bidcount,
                            bids: data.bpop_bidcount,
                            cbidtext: 'Current Bid',
                        }
                        setViewProduct(product)
                    } else {
                        // console.log(
                        //     parseInt(productToChange.cbidnot),
                        //     'parseInt(productToChange.bid_or_not) > 0',
                        // )
                        if (
                            parseInt(productToChange.bid_or_not) > 0 ||
                            parseInt(productToChange.cbidnot) > 0
                        ) {
                            if (user_id === parseInt(data.bpop_higher)) {
                                if (
                                    parseInt(productToChange.next_bid) < parseInt(data.bpop_nextbid)
                                ) {
                                    if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                        data.bidtopstatus = `You are winning this item at $${parseFloat(
                                            data.bpop_wprice,
                                        ).toUSFormat()}.`
                                    } else {
                                        data.bidtopstatus = `You are winning this item at $${parseFloat(
                                            data.bpop_wprice,
                                        ).toUSFormat()}. Your autoBid of $${parseFloat(
                                            data.bpop_wprice_morehigh,
                                        ).toUSFormat()} has been placed successfully.`
                                    }
                                    product = {
                                        ...productToChange,
                                        latestbid: data.bpop_wprice,
                                        nobids: data.bpop_bidcount,
                                        incrementamt: productToChange.incrementamt,
                                        next_bid: data.bpop_nextbid_org,
                                        wprice: data.bpop_wprice,
                                        bidtopstatus: data.bidtopstatus,
                                        bid_count: data.bpop_bidcount,
                                        bids: data.bpop_bidcount,
                                        date_closed,
                                    }
                                    setViewProduct(product)
                                } else {
                                    if (
                                        parseInt(product.rprice || 0) <= parseInt(data.bpop_wprice)
                                    ) {
                                        if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                            data.bidtopstatus = `You are winning this item at $${parseFloat(
                                                data.bpop_wprice,
                                            ).toUSFormat()}.`
                                        } else {
                                            data.bidtopstatus = `You are winning this item at $${parseFloat(
                                                data.bpop_wprice,
                                            ).toUSFormat()}. Your autoBid of $${parseFloat(
                                                data.bpop_wprice_morehigh,
                                            ).toUSFormat()} has been placed successfully.`
                                        }
                                    } else {
                                        if (data.bpop_wprice === data.bpop_wprice_morehigh) {
                                            data.bidtopstatus = `Your bid of $${parseFloat(
                                                data.bpop_wprice,
                                            ).toUSFormat()} on this item placed, but reserve price not yet met.`
                                        } else {
                                            data.bidtopstatus = `Your bid of $${parseFloat(
                                                data.bpop_wprice,
                                            ).toUSFormat()} and auto bid of $${parseFloat(
                                                data.bpop_wprice_morehigh,
                                            ).toUSFormat()} on this item placed, but reserve price not yet met.`
                                        }
                                    }
                                    product = {
                                        ...productToChange,
                                        latestbid: data.bpop_wprice,
                                        nobids: data.bpop_bidcount,
                                        incrementamt: productToChange.incrementamt,
                                        wprice: data.bpop_wprice,
                                        bidtopstatus: data.bidtopstatus,
                                        bid_count: data.bpop_bidcount,
                                        bids: data.bpop_bidcount,
                                        date_closed,
                                    }
                                    setViewProduct(product)
                                }
                            } else {
                                product = {
                                    ...productToChange,
                                    latestbid: data.bpop_wprice,
                                    nobids: data.bpop_bidcount,
                                    incrementamt: data.bpop_increment,
                                    next_bid: data.bpop_nextbid_org,
                                    wprice: data.bpop_wprice,
                                    bid_count: data.bpop_bidcount,
                                    bids: data.bpop_bidcount,
                                    bidtopstatus: `You've been outbid on this item. Current leading bid is $${parseFloat(
                                        data.bpop_wprice,
                                    ).toUSFormat()}`,
                                    bid_or_not: 1,
                                    date_closed,
                                }
                                setViewProduct(product)
                            }
                        } else {
                            product = {
                                ...productToChange,
                                latestbid: data.bpop_wprice,
                                nobids: data.bpop_bidcount,
                                incrementamt: data.bpop_increment,
                                next_bid: data.bpop_nextbid_org,
                                wprice: data.bpop_wprice,
                                bid_count: data.bpop_bidcount,
                                bids: data.bpop_bidcount,
                                cbidtext: 'Current Bid',
                                date_closed,
                            }
                            setViewProduct(product)
                        }
                    }
                    // getBidHistoryProduct({ product_id: project_id })
                }
            } else if (type === 'watchlistAdded') {
                product = {
                    ...productToChange,
                    watchlistid: data.status === 'added' ? 1 : 0,
                }
                setViewProduct(product)
            } else if (type === 'cancelbidemitted') {
                // console.log(data, 'cancellllllllllll')
                if (Object.keys(data).length) {
                    // console.log('current id vs new lot id')

                    if (parseInt(data.project_id) === parseInt(productToChange.id)) {
                        product = {
                            ...productToChange,
                            nobids: data.bpop_bidcount,
                            wprice: data.bpop_wprice,
                            next_bid:
                                data.bpop_bidcount == 0 ? data.bpop_wprice : data.bpop_nextbid,
                            latestbid: data.bpop_bidcount == 0 ? null : data.bpop_wprice,
                        }
                        setViewProduct(product)
                    }
                }
            }
        }
    }
}

export const messageHandlerAuction = (data, auction, user, setAlert, setViewAuction, type) => {
    let user_id = user.id
    if (data.bpop_auctionid) {
        const index = auction.findIndex((s) => s.id === parseInt(data.bpop_auctionid, 10))
        let auctionToChange = auction[index]
        if (index !== -1) {
            if (type === 'bidAddtime') {
                let date_closed = auction[index].date_closed
                if (parseInt(data.bpop_belowFiveAuction) === 1) {
                    date_closed = data.bpop_belowFiveIncrement
                }
                if (data.status !== 'failed') {
                    auction[index] = {
                        ...auctionToChange,
                        date_closed,
                    }
                    setViewAuction([...auction])
                }
            }
        }
    }
}

export const messageHandleBuynow = (
    data,
    product,
    user,
    setAlert,
    setViewCartItems,
    type,
    setRemoveid,
) => {
    if (user) {
        // console.log('user inside', user)
        let user_id = user.id
        if (type === 'realclosedupdatesbuynow') {
            var index = product.findIndex((s) => s.product_id === parseInt(data.pid, 10))
        } else {
            var index = product.findIndex((s) => s.product_id === parseInt(data.id, 10))
        }
        // console.log('data', data)
        if (index !== -1) {
            if (type === 'realclosedupdatesbuynow') {
                if (parseInt(user_id) != parseInt(data.usr)) {
                    setAlert(
                        'Sorry,Some One Has Bought Your Item Or Item End time Reached for ' +
                            product[index].title +
                            '.So It Removed From Your Cart.',
                        'error',
                    )
                    product.splice(index, 1)
                    setViewCartItems([...product])
                }
            }
            if (type === 'checkbuynowavailable') {
                // console.log('innnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn')
                if (parseInt(data.bpop_wprice) >= parseInt(product[index].bprice)) {
                    // setAlert(
                    //     'Sorry,Bid Amount Exceeded From Buynow Price' +
                    //         product[index].title +
                    //         '.So It Removed From Your Cart.',
                    //     'error',
                    // )
                    // setRemoveid(product[index].product_id)
                    //product.splice(index, 1)
                    product[index].wprice = data.bpop_wprice
                    setViewCartItems([...product])
                }
            }
        }
    }
}

export const timeHandler = (data, product, user, setAlert, setViewProduct, type, showAlert) => {
    // if (type == 'singletimehandler') {
    //     if (new Date(product.date_added) > new Date()) {
    //         let productdata = product
    //         productdata.market_status = 'future'
    //         setViewProduct(productdata)
    //     }
    // }
}

export const followHandler = (socketData, setLocalData, user, user_id, setFollowed) => {
    // console.log(socketData, user, user_id)
    if (parseInt(user_id) === parseInt(socketData.user_id)) {
        if (parseInt(socketData.user) === parseInt(user?.id)) {
            setFollowed(socketData.followed)
        }
        setLocalData((prev) => ({
            ...prev,
            follow_count: socketData.follow_count,
            unfollow_count: socketData.unfollow_count,
        }))
    }
}

export const make_an_offer_update_socket = (
    data,
    product,
    user,
    setAlert,
    setViewProduct,
    type,
) => {
    if (parseInt(data.project_id) == parseInt(product.id)) {
        var product_update = product
        product = {
            ...product_update,
            market_status: 'sold',
        }
        setViewProduct(product)
    }
}
