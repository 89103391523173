import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_FAIL,
    CLEAR_ERROR,
    LOGIN_SUCCESS,
    USER_LOADED,
    USER_DETAILS_LOADED,
    RECENT_SEARCH,
    ADD_RECENT_SEARCH,
    USER_LOADED_INITAL,
    CLEAR_INITIAL,
    AUTH_ERROR,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    ONLY_ONCE,
    COUNT_LOADED,
    LOGOUT,
    GET_ACTIVE_PLUGINS,
} from './authTypes'

export default (state, action) => {
    switch (action.type) {
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                isAdmin:
                    action.payload.data.email === 'seller@laauction.com' ||
                    action.payload.data.email === 'seller@laauctiondepot.com' ||
                    action.payload.data.email === 'seller_test@laauctiondepot.com'
                        ? true
                        : false,
                loading: false,
                user: action.payload.data,
                membership: action.payload.membership,
            }
        case GET_ACTIVE_PLUGINS:
            return {
                ...state,
                active_plugins: action.payload.data,
            }
        case USER_DETAILS_LOADED:
            return {
                ...state,
                userDetails: action.payload.data,
                userProducts: action.payload.product_data || {},
            }
        case COUNT_LOADED:
            return {
                ...state,
                userCount: {
                    watchlistCount: action.payload.watchlistCount,
                    itemwonCount: action.payload.itemwonCount,
                    itemlostCount: action.payload.itemlostCount,
                    savesearchCount: action.payload.savesearchCount,
                    itemunPaidCount: action.payload.itemunPaidCount,
                    itemsoldCount: action.payload.itemsoldCount,
                },
            }
        case ONLY_ONCE:
            return {
                ...state,
                onlyOnce: true,
            }

        case USER_LOADED_INITAL:
            return {
                ...state,
                isAuthenticated: true,
                isAdmin:
                    action.payload.data.email === 'seller@laauction.com' ||
                    action.payload.data.email === 'seller@laauctiondepot.com' ||
                    action.payload.data.email === 'seller_test@laauctiondepot.com'
                        ? true
                        : false,
                initialLogin: true,
                loading: false,
                user: action.payload.data,
                membership: action.payload.membership,
            }
        case CLEAR_INITIAL:
            return {
                ...state,
                initialLogin: false,
            }
        case LOGIN_SUCCESS:
            localStorage.setItem('token', action.payload.data)
            return {
                ...state,
                token: action.payload.data,
                isAuthenticated: true,
                loading: false,
            }
        case RECENT_SEARCH:
            return {
                ...state,
                recent_search: action.payload,
            }
        case ADD_RECENT_SEARCH:
            return {
                ...state,
                recent_search: [action.payload, ...state.recent_search],
            }
        case REGISTER_SUCCESS:
        case REGISTER_FAIL:
        case LOGIN_FAIL:
        case AUTH_ERROR:
        case LOGOUT:
            localStorage.removeItem('token')
            return {
                ...state,
                token: null,
                isAdmin: false,
                isAuthenticated: false,
                loading: false,
                user: null,
                membership: {},
                responseStatus: action.payload,
            }
        case CLEAR_ERROR:
            return {
                ...state,
                responseStatus: null,
            }
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
