import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import Routes from './Routes'
import { SnackbarProvider } from 'notistack'
import ScrollToTop from './Routes/ScrollToTop'
import './App.css'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { setAuthToken, setIPAddress } from './Product/common/api'
import AuthState from './Product/context/auth/authState'
import UserState from './Product/context/user/userState'
import ProductState from './Product/context/product/productState'
import AuctionioProductState from './Product/context/auctionio/product/productState'
import AuctionioAuctionState from './Product/context/auctionio/auction/auctionState'
import BuyerState from './Product/context/buyer/buyerState'
import CartState from './Product/context/cart/cartState'
import AlertState from './Product/context/alert/alertState'
import Alerts from './Product/common/alert'
import CardState from './Product/context/stripe/card/cardState'
import CustomerState from './Product/context/stripe/customer/customerState'
import AuctionPayState from './Product/context/stripe/auctionPay/auctionPayState'
import PosState from './Product/context/plugin/pos/posState'
import BankState from './Product/context/stripe/bank/bankState'
import PayState from './Product/context/payment/payState'
import PluginState from './Product/context/plugin/pluginState'
import HeadAlert from './Product/components/molecules/HeadAlert'
import publicIp from 'public-ip'
import ipLocation from 'iplocation'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import DirectStripeState from './Product/context/directStripe/directStripeState'
import SEO from './utils/CommonFunctionality/SEO'
import { I18nextProvider } from 'react-i18next'
import i18n from './utils/CommonFunctionality/language-i18n'
require('dotenv').config()

global.site_url = process.env.REACT_APP_URL
global.site_base_url = process.env.REACT_APP_BASE_URL
global.images_url = process.env.REACT_APP_SOCKET_URL + '/uploads/'
global.ignoreStates = []
global.ignoreCountry = []
global.defaultCountry = 'US'
global.LiquidationHasAuctionCart = false
global.getUserCount = true
// global.registerToken = true
Number.prototype.toUSFormat = function (n = 2) {
    return this.toLocaleString('en-US', {
        minimumFractionDigits: n,
        maximumFractionDigits: n,
    })
}
Date.prototype.addHours = function (h) {
    this.setHours(this.getHours() + h)
    return this
}

function App() {
    useEffect(() => {
        // async function fetchData() {
        //     const ipaddress = await publicIp.v4()
        //     const ipLoc = await ipLocation(ipaddress)
        //     console.log('ipLoc', ipLoc)
        //     setIPAddress(ipaddress)
        // }
        // fetchData()
    }, [])

    return (
        <HelmetProvider>
            <SEO
                title={global?.storeDetails?.name}
                description={global?.storeDetails?.description}
            />
            <I18nextProvider i18n={i18n}>
                <AlertState>
                    <AuthState>
                        <UserState>
                            <ProductState>
                                <AuctionioProductState>
                                    <AuctionioAuctionState>
                                        <BuyerState>
                                            <PosState>
                                                <CartState>
                                                    <DirectStripeState>
                                                        <AuctionPayState>
                                                            <CustomerState>
                                                                <PluginState>
                                                                    <CardState>
                                                                        <BankState>
                                                                            <PayState>
                                                                                <BrowserRouter>
                                                                                    <MuiPickersUtilsProvider
                                                                                        utils={
                                                                                            MomentUtils
                                                                                        }
                                                                                    >
                                                                                        <SnackbarProvider
                                                                                            maxSnack={
                                                                                                3
                                                                                            }
                                                                                            autoHideDuration={
                                                                                                3000
                                                                                            }
                                                                                        >
                                                                                            <div className="App">
                                                                                                <Alerts />
                                                                                                <HeadAlert />
                                                                                                <ScrollToTop />
                                                                                                <Routes />
                                                                                            </div>
                                                                                        </SnackbarProvider>
                                                                                    </MuiPickersUtilsProvider>
                                                                                </BrowserRouter>
                                                                            </PayState>
                                                                        </BankState>
                                                                    </CardState>
                                                                </PluginState>
                                                            </CustomerState>
                                                        </AuctionPayState>
                                                    </DirectStripeState>
                                                </CartState>
                                            </PosState>
                                        </BuyerState>
                                    </AuctionioAuctionState>
                                </AuctionioProductState>
                            </ProductState>
                        </UserState>
                    </AuthState>
                </AlertState>
            </I18nextProvider>
        </HelmetProvider>
    )
}

export default App
