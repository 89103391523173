import React, { useContext, useEffect } from 'react'
import './ContactUs.css'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { mapData, handleRedirectInternal } from '../../../Product/common/components'
import PrimaryButton from '../../../Product/components/atoms/PrimaryButton'
import AuthContext from '../../../Product/context/auth/authContext'
import UserContext from '../../../Product/context/user/userContext'
import Layout from '../Layout'

function ContactUs() {
    const authContext = useContext(AuthContext)
    const userContext = useContext(UserContext)
    const { send_contactus, responseStatus } = userContext
    const { user, loadUser } = authContext
    const history = useHistory()
    const contactEmail = global?.storeDetails?.contact_email
    const contactPhone = global?.storeDetails?.phone
    const contactAddress1 = `${global?.storeSeller?.address1}`
    const contactAddress2 = `${global?.storeSeller?.city},  ${global?.storeSeller?.state} - ${global?.storeSeller?.zip}`

    const validationArray = Yup.object({
        name: Yup.string()
            .trim()
            .matches(/^[a-zA-Z ]*$/g, 'Special characters not allowed')
            .max(15, 'Maximum 15 characters')
            .required('Required'),

        from_email: Yup.string().email('Invalid email format').required('Required'),
        phone_number: Yup.string()
            .trim()
            .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
            .required('Required'),
        subject: Yup.string().required('Required'),
        messages: Yup.string().required('Required'),
    })

    const formik = useFormik({
        initialValues: {
            name: '',
            last_name: '',
            from_email: '',
            phone_number: '',
            subject: '',
            messages: '',
            is_send_email: 1,
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            send_contactus(values)
        },
    })

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'send_contactus') {
                if (responseStatus.status === 'success') {
                    handleRedirectInternal(history, '')
                }
            }
        }
    }, [responseStatus])

    const contactInfo = [
        {
            label: 'Name',
            name: 'name',
            type: 'text',
            placeholder: 'Enter your first name',
            class: 'col-sm-6 col-12',
            autoFocus: true,
            formik: formik,
        },

        {
            label: 'Email address',
            name: 'from_email',
            type: 'text',
            placeholder: 'Enter your email address',
            class: 'col-sm-6 col-12',
            formik: formik,
        },
        {
            label: 'Phone number',
            name: 'phone_number',
            type: 'phone',
            placeholder: 'Enter your phone number',
            class: 'col-sm-6 col-12',
            formik: formik,
        },
        {
            label: 'Subject',
            name: 'subject',
            type: 'text',
            placeholder: 'Enter your subject',
            class: 'col-12',
            formik: formik,
        },
        {
            label: 'Message',
            name: 'messages',
            type: 'textarea',
            placeholder: 'Enter your message',
            class: 'col-12',
            formik: formik,
        },
    ]
    useEffect(() => {
        loadUser()
    }, [])

    useEffect(() => {
        if (user) {
            formik.values.name = user?.first_name + ' ' + user?.last_name || ''
            formik.values.phone_number = user?.phone_number || ''
            formik.values.from_email = user?.email || ''
            formik.values.subject = 'General Enquiry'
        }
    }, [user])

    return (
        <Layout>
            <div className="contact-main-container">
                <div className="contact-banner">
                    <img src="/assets/images/contact_us.png" alt="" />
                </div>
                <div className="container cu-margin-125">
                    <div className="row">
                        <div className="col-xs-12 col-md-8 padtop">
                            <div className="contactform">
                                <div className="cu-head">Contact us</div>
                                <div className="text-center scontact-us-wrap flex-btnscol">
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="row">
                                            {Object.values(mapData(contactInfo))}
                                            <div className="col-12">
                                                <PrimaryButton label="Submit" type="submit" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-4 padtop">
                            <div className="contactform cfRt">
                                <div className="cu-head">Reach Us</div>
                                {contactAddress1 &&
                                    contactAddress1 != 'null' &&
                                    contactAddress2 &&
                                    contactAddress2 != 'null' && (
                                        <div className="media">
                                            <div className="media-left">
                                                <span className="material-icons">location_on</span>
                                            </div>
                                            <div className="media-body">
                                                <h5 className="ru-head">Address</h5>
                                                <span className="ru-con">{contactAddress1}</span>
                                                <span className="ru-con">{contactAddress2}</span>
                                            </div>
                                        </div>
                                    )}
                                {contactEmail && (
                                    <div className="media">
                                        <div className="media-left">
                                            <span className="material-icons">mail</span>
                                        </div>
                                        <div className="media-body">
                                            <h5 className="ru-head">Email address</h5>
                                            <a href={`mailto:${contactEmail}`} className="ru-con">
                                                {contactEmail}
                                            </a>
                                        </div>
                                    </div>
                                )}
                                {/* {contactPhone && (
                                    <div className="media">
                                        <div className="media-left">
                                            <span className="material-icons">call</span>
                                        </div>
                                        <div className="media-body">
                                            <h5 className="ru-head">Call</h5>
                                            <a href={`tel:${contactPhone}`} className="ru-con">
                                                <span dir="ltr">{contactPhone}</span>
                                            </a>
                                        </div>
                                    </div>
                                )} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ContactUs
